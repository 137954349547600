
//   FOOTER  ----------------------------------------



$ft: $t + 0px;
$fm: $m + 100px;


$footer-color: darken(#0e190d,0%);


footer#footer {
    min-height: 58px;
    margin-top: -5px;
    position: relative;
    z-index: 98;
    // z-index:105;
    &::before {
        content:"";
        display: inline-block;
        margin-top: -150px;
        height:100px;
        width:100%;
        position: relative;
        background: linear-gradient(to bottom,
            transparentize($footer-color, 1) 0%,
            darken($footer-color,0%) 90%,
            darken($footer-color,0%));
        z-index:105;
        @include brk($m) {
            height:50px;
            margin-top: -270px;
        }
    }
    background:linear-gradient(to bottom, darken($footer-color,0%) 0%, darken($footer-color,2%) 100%);
    #footer_top {
        overflow-x:hidden;
    }
    #footer_middle {
        background: linear-gradient(to bottom, darken($footer-color,0%) 0%, darken($footer-color,5%) 100%);
        display: inline-block;
        width:100%;
        padding-top: 67px;
        padding-bottom: 30px;
        &.home {
        }
        @include brk($ft) { padding-bottom:25px; }
        .col {
            padding:0px 0 70px;
            @include brk($td) {
                width: 100% !important;
                margin-bottom:50px;
                padding-bottom: 50px;
                border-bottom:1px solid rgba(255,255,255,0.1);
            }
            &:last-child {
                border-bottom:1px solid rgba(255,255,255,0);
                margin-bottom:0px;
                padding-bottom: 20px;
            }
            .btn:hover {
                background:$touche1;
            }
        }
        .title-container {
            position: relative;
            .title {
                line-height: 1;
                font-size: 16px;
                font-weight: $bold;
                font-family: $family2;
                text-transform: uppercase;
                color:#fff;
                margin-bottom:29px;
                padding-left:34px;
                &.coat-of-arms {
                    padding-left:46px;
                }
            }
            .icon {
                position: absolute;
                top:-1px;
                left:0;
                width:20px;
                height:20px;
                svg  {
                    path {fill:$touche1}
                }
                &.coat-of-arms {
                    position: absolute;
                    top:-10px;
                    left:-5px;
                    width:40px;
                    height:40px;
                    .letters {
                        display: none;
                    }
                    svg  {
                        path {fill:$touche1}
                    }
                }
            }
        }
        .btn {
            font-size:12px;
            font-weight: $bold;
            font-family: $family2;
            letter-spacing: 1px;
            text-transform: uppercase;
            background: rgba(0,0,0,0.7);
            padding:10px 20px;
            margin-top: 40px;
            color:transparentize(#fff, 0.2); 
            &:hover {
                background:darken(#0e190d, 10%);
                color:transparentize(#fff, 0); 
            }
        }
    }
    #footer_bottom {
        // min-height:60px;
        padding-top: 10px;
        margin-top:-5px;
        padding-bottom: 10px;
        background:darken($footer-color, 50%);
        @include brk($ft) { padding-bottom:10px; }
        @include brk($fm) { padding-top: 16px; }
    }
}






footer .footer-disclaimer {
    width:70%;
    padding: 20px 0 15px;
    display: inline-block;
    float: left;
    text-align: left;
    @include brk($td) {
        width: 65%;
    }
    @include brk($tp) {
        width:100%;
        padding: 20px 0 15px;
        // text-align: center;
        // margin: 0 auto;
    }
    .logo.aemn {
        width: 90px;
        height: 80px;
        display: inline-block;
        float: left;
        margin-top: 0px;
        filter:brightness(90%);
        .st0{clip-path:url(#SVGID_2_);fill:#fff;stroke:#000;stroke-width:10;}
        .st1{clip-path:url(#SVGID_4_);fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
        .st2{fill:none;stroke:#000;stroke-width:0.652;stroke-miterlimit:2.613;}
        .st3{clip-path:url(#SVGID_6_);fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
        .st4{fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
        .st5{clip-path:url(#SVGID_8_);fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
        .st6{clip-path:url(#SVGID_10_);}
        &:hover {
            filter:brightness(100%);
        }
        @include brk($tp) {
            width:100px;
            margin-top: 0px;
            display: block;
            margin: 0 auto;
            float: none;
        }
    }
    .texts-container {
        display: inline-block;
        float: right;
        width: calc(100% - 90px);
        padding-top: 6px;
        padding-left: 25px;
        @include brk($m) {
            width: 100%;
            padding-left: 0px;
            margin-bottom: 20px;
            text-align: center;
            float: none;
        }
        .title-section {
            text-transform: uppercase;
            font-family: $family2;
            font-weight: $bold;
            margin-bottom: 5px;
        }
        .title-section, p {color:#aaa;}
        p {font-size: 11px}
    }
}






footer #footer_top .next-event {
    padding: 70px 10% 70px 0%;
    // border-bottom: 1px solid transparentize(#fff, 0.95);
    margin-bottom: 20px;
    .title-section {
        font-size: 14px;
        font-weight: $bold;
        font-family: $family2;
        clear: both;
        animation: fade 1s;
        color:$touche1;
        letter-spacing: 3.2px;
        word-spacing: 4px;
        margin-bottom: 20px;
        text-transform: uppercase;
        line-height: 1.05;
        text-shadow: 4px 2px 8px transparentize(#000, 0.6);
        // TRAIT ------------------------
        position: relative;
        &::after {
            content:"";
            position: absolute;
            top:8px;
            left:-94px;
            height:1px;
            width:70px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize($touche1, 0);
            @include brk($t) {
                display:none;
            }
        }
    }
    ul {
        li {
            transition: border 1s;
            border-top: 1px solid transparentize(#fff,0.95);
            &:first-child {border-top: 1px solid transparentize(#fff,1)}
            a {
                display: inline-block;
                width:100%;
                height:100%;
                padding: 24px 0 22px;
                @extend .cache-left;
                @extend .cache-right;
            }
            p {
                font-size:13px;
                font-weight: $bold;
                font-family: $family2;
                letter-spacing: 1px;
                text-transform: uppercase;
                display: inline-block;
                color:transparentize(#fff, 0.5);
            }
            .date {
                margin-right:10px; 
            }
            .place, .country {
            }
            .place {
                &:after {
                    content:"|";
                    margin-left: 5px;
                    margin-right:1px; 
                    color:transparentize($touche1, 0.5);
                }
                
            }
            .title {
                font-size: 30px;
                display: block;
                font-weight: $bold;
                font-family: $family2;
                letter-spacing: 0px;
                text-transform: uppercase;
                color:transparentize(#fff, 0.1);
            }
            &:hover {
                a[href] {
                    background: transparentize($touche1, 0.3);
                }
                + li {
                    border-top: 1px solid transparentize(#fff,1)
                }
            }
        }
    }
}










footer .col-logo {
    width:30%;
    margin-right: 5%;
    float: left;
    padding-right:4%;
    position: relative;
    display: none;
    .logo-container {
        height:40px;
        width:165px;
        float: left;
        z-index: 1;
        position:relative;
        display: block;
        margin-left: -2px;
        @include brk ($t) {
        }
        @include brk ($m) {
        }
        .logo-image {
            display: inline-block;
            height:100%;
            z-index: 10;
            width:100%;
            path {fill:transparentize(#fff, 0.1)}
        }
    }
}






// footer .col-agenda {
//     width:30%;
//     margin-right: 5%;
//     float: left;
//     padding-right:4%;
//     position: relative;
//     li {
//         transition: border 1s;
//         border-top: 1px solid transparentize(#fff,0.8);
//         &:first-child {border-top: 1px solid transparentize(#fff,1)}
//         a {
//             display: inline-block;
//             width:100%;
//             height:100%;
//             padding: 10px 0 12px;
//             @extend .cache-left;
//         }
//         &:hover {
//             a[href] {
//                 background: transparentize(#000, 0.2);
//             }
//             + li {
//                 border-top: 1px solid transparentize(#fff,1)
//             }
//         }
//         p, span {
//             display: inline;
//             color:transparentize(#fff, 0.3);
//             font-weight: $light;
//         }
//         .date {
//             font-size:12px;
//             font-weight: $bold;
//             font-family: $family2;
//             text-transform: uppercase;
//             display: inline-block;
//             margin-right:10px; 
//             color:transparentize(#fff, 0.1);
//         }
//         .place, .country {
//             font-size: 12px;
//             font-weight: $bold;
//             font-family: $family2;
//             color:transparentize(#fff, 0.1);
//             display: inline-block;
//             text-transform: uppercase;
//         }
//         .place {
//             &:after {
//                 content:"|";
//                 margin-left: 5px;
//                 margin-right:1px; 
//                 color:transparentize($touche1, 0.5);
//             }
            
//         }
//         .title {
//             font-size: 14px;
//             display: block;
//         }
//     }
// }





footer .col-newsletter {
    width:35%;
    float: left;
}



footer #bloc_newsletter {
    margin-top:0px;
    float: left;
    @include brk($fm) {width:100%}
    .txt-newsletter {
        font-weight: $normal;
        letter-spacing: 0.5px;
        user-select:none;
        line-height: 2;
        margin-bottom: 25px;
        p {
            color:transparentize(#fff, 0.1);
            font-family: $family1;
            font-size: 14px;
        }
        span {
            letter-spacing: 0.5px;
            color:$white;
            font-weight: $normal;
        }
        @include brk($ft) {
            margin-bottom: 22px;
        }
        @include brk ($fm) {
            width:100%;
            margin: 0px auto 23px;
            // text-align: left;
        }
    }
    form {
        margin-top: 30px;
        position: relative;
        max-width:320px;
        background:rgba(0,0,0,0.7);
        // border: 1px solid #e7ebed;
        // float: left;
        height:56px;
        @include brk ($fm) {
            // width:280px;
            // margin: 20px auto 0px;
        }
    }
    input {
        font-size: 14px;
        font-weight: $light;
        font-family: $family1;
        height:100%;
        padding:0px 20px 0px 20px;
        width:calc(100% - 73px);
        color:rgba(255,255,255,1);
        float: left;
        border-radius:12px;
        letter-spacing: 1px;
        text-align: left;
        transition: border 0.5s;
        // border:1px solid rgba(255,255,255,0.2);
        &:focus {
            // border:1px solid rgba(255,255,255,0.3);
        }
        @include brk ($fm) {
        }
    }
    button {
        font-size: 12px;
        font-family: $family2;
        color:transparentize(#fff, 0.2); 
        border-left:1px solid rgba(255,255,255,0.2);
        // border-radius:50%;
        width:60px;
        height:100%;
        float: right;
        font-weight: $black;
        transition: color 0.1s, background 0.1s, border 0.3s;
        letter-spacing: 1.5px;
        &:hover {
            color:transparentize(#fff, 0); 
            background: $touche1
        }
        @include brk ($fm) {
        }
    }
}



#bloc_newsletter {
    .response_box {
        float: left;
        clear: both;
        font-size: 12px;
        color:rgba(255,255,255,1);
        font-weight: $light;
        font-style: italic;
        margin-top:12px;
        width:calc(100% - 73px);
        text-align: left;
        padding-left: 22px;
        @include brk($ft) {margin-top:8px;}
        &.success {color:green;}
        &.error {color:red;}
        // display: none;
    }
}






#bloc_newsletter {


    // FIX FOR CHROME AUTOFILL
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #2a2a2a inset;
        -webkit-text-fill-color: rgba(255,255,255,0.8) !important;
        border-bottom:1px solid #2a2a2a;
        &:focus {border-bottom:1px solid #143467!important;}
    }


    /**************  PLACEHOLDER ADJUST   ***************/
    input::placeholder, textarea::placeholder {

        font-size: 14px;
        font-weight: $light;
        color: transparentize(#fff, 0.2);
        text-align: left !important;
        transition: opacity 0.3s;
        opacity: 1;
        @include brk($ft){ font-size:14px; }
        @include brk($fm){ font-size:14px; }
    }
    input:focus, textarea:focus {
        &::placeholder { opacity: 0.25; }
    }


}







footer .col-contact {
    width:50%;
    float: right;
    p, span {
        font-size: 14px;
        font-family: $family1;
        color:transparentize(#fff, 0.1);
        line-height: 1.8;
        font-weight: $light;
    }
}





footer #copyright_line {
    display:inline-block;
    width:100%;
    animation: fade 3s;
    text-align: left;
    margin-left: -15px;
    @include brk($ft) { text-align: left;}
    @include brk($fm) { text-align: center; margin-left: 0px; }
    a, .by-module >div {
        font-size: 11px;
        color:rgba(255,255,255,0.5);
        letter-spacing:0.6px;
        font-weight: $normal;
        line-height: 1.5;
        text-transform: uppercase;
        transition: color 0.5s;
    }
    a {
        font-weight: $bold;
        font-family: $family2;
        display: inline-block;
        padding: 15px 15px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        @include brk($fm) { 
            // display: inline-block;
            // width:100%;
            // padding: 0px 15px;
            // margin: 5px 0;
        }
        &:hover {
            &, &.by-module >div {color:#eee;}
            .st0{stroke:#FFFFFF;}
        }
        &:first-child {border-bottom:none}
        &:before {
            content:"";
            display: inline-block;
            position: absolute;
            top:16px;
            left:0;
            width:1px;
            height:15px;
            background:rgba(255,255,255,0.15);
            @include brk($fm){display:none;}
        }
    }
    a:first-child:before {display:none;}
}

