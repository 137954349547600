
/* NAV TABLET
====================*/




// LEVEL 1
$bg-l1: lighten(#041a26, 8%);
$bg-l1-top: darken(#041a26,0%);
$bg-l1-robe: darken(#041a26, 0%);


// LEVEL 2
$bg-l2: darken($touche1, 10%);
$bg-l2-top: $red;
$bg-l2-robe: darken($touche1, 10%);



// LEVEL 3
$bg-l3: darken(#4cacb2, 6%);
$bg-l3-top: $dark-red;







// @import "hamburger/00_hamburger";
#hamburger_container {
    position: fixed;
    // background: $touche_de_couleur;
    animation: fade 1s;
    width:$header-height; 
    height:$header-height;
    z-index: 1010;
    right:0;
    top:0;
    margin-right: - ($header-height + 50px);
    &.active {
        // animation:none!important
        background: $touche1;
    }
    @include brk($t) {
        display:inline-block;
        margin-right: 0;
        width:$header-height-tablet; 
        height:$header-height-tablet;
    }
    @include brk($m) {
        width:$header-height-mobile; 
        height:$header-height-mobile;
    }
    // button {
    //     &:before {
    //         content:"";
    //         @extend .abs;
    //         background:url('../assets/images/sandwish-gradient.png');
    //         height:80%;
    //         background-size:cover;
    //         background-position:0% 0%;
    //         opacity: 1;
    //     }
    // }
}




// CONTAINER ------------------------------------

@keyframes open_menu {
    0% { transform:translate3d(100%, 0px, 0px); }
    100% { transform:translate3d(0, 0px, 0px); }
}


#nav_mobile_container {
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s, opacity 0.3s;
    z-index: 990;
    height:100%;
    top:0px;
    right:0;
    overflow: hidden;
    position:fixed;
    display: none;
    user-select:none;
    &.opened {
        display: block;
        animation: open_menu 0.3s;
    }
    @include brk ($t) {width:320px;}
    @include brk ($m) {max-width:calc(100% - 71px);}
}




// LEVEL 1 --------------------------------------------

#nav_mobile {
    height:100%;
    overflow: hidden;
    overflow-y: scroll;
    width: calc(100% + 20px);
    background:$bg-l1-robe;
    >ul {
        margin-top:$header-height;
        @include brk($t) {margin-top:$header-height-tablet;}
        @include brk($m) {margin-top:$header-height-mobile;}
    }
    >ul li:first-child {
        padding-top:5px; 
        a {border:none!important;}
    }
    >ul li:last-child {
        padding-bottom:3px; 
        a {border:none!important;}
    }
    >ul li {
        animation-fill-mode: both;
        @for $i from 1 through 20 {
            &:nth-child(#{$i}) { 
                animation-delay: ((0.06s * $i) - 0.05s);
                animation-duration: 0.2s + (0.05s * $i);
                animation-name: fade_scale_95;
            }
        }
    }
    >ul li a {
        line-height: 1.8;
        font-size:13px;
        position:relative;
        padding: 12px 0 12px 30px;
        letter-spacing: 1.2px;
        animation: fade 1.5s;
        font-weight: $bold;
        text-transform: uppercase;
        font-family: $f2;
        border-top:1px solid transparentize(#000,0.9);
        transition: border 0.6s;
    }
    >ul>li {
        background:$bg-l1;
    }
    >ul li {
        &:hover, &.current-menu-item, &.current-menu-ancestor {
            background:darken($bg-l1, 6%);
            >a, + li>a {border-top:1px solid transparentize(#000,1);}
        }
    }
    >ul>li>a {
        display: inline-block;
        position: relative;
        width:100%;
        color:$white;
    }
    >ul>li.menu-item-has-children>a::after {
            content:"›";
            position: absolute;
            font-size:28px;
            font-weight: $normal;
            height:100%;
            box-sizing: inherit;
            color:$touche1;
            width:62px; 
            top:0px;
            right:0px;
            padding-left:25px;
            padding-top: 4px;
            // background:#edecec;
            text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.15);
            @include brk($m) {}
        }
}






// LEVEL 2 --------------------------------------------


#nav_mobile >ul>li {
    ul {
        z-index: 1012;
        top:$header-height;
        @include brk($tablet) {top:$header-height-tablet;}
        @include brk($mobile) {top:$header-height-mobile;}
        li>a {
            letter-spacing: 1px;
            color:#eee;
            display: block;
            opacity: 0.1;
            transition: opacity 0.35s;
        }
        &.opened {
            transform: translate3d(7.4%, 0px, 0px);
            >li>a {
                opacity: 1;
            }
        }
    }
    >ul>li {
        background:$bg-l2;
    }
    >ul {
        background:$bg-l2-robe;
        overflow-y: scroll;
        overflow-x: hidden;
        height:100%;
        width: 108.1%;
        position:absolute;
        right:1px;
        transform:translate3d(108%, 0px, 0px);
        transition: transform 0.3s;
    }
    >ul>li {
        &.current-menu-item, &:hover {
            background:darken($bg-l2, 6%);
            >a, + li>a {border-top:1px solid transparentize(#fff,1);}
        }
    }
}






#nav_mobile {
    .icon-container {
        display: inline-block;
        float: left;
        height: 40px;
        width: 40px;
        position: relative;
        margin-left: 0px;
        margin-right: 16px;
        border: 1px solid transparentize(#fff, 0.8);
        border-radius:50%;
        transition: transform 0.6s;
        position: absolute;
        top: 18px;
        left: 15px;
        @include brk($m) {
            top: 10px;
            left: 16px;
        }
        .icon {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        * {
            fill:#fff;
        }
        &:hover {
            background: darken(#002942, 10%);
            border: 1px solid transparentize(darken(#002942, 10%), 0);
        };
    }

}









.btn-home {
    z-index: 1012;
    position: absolute;
    display: inline-block;
    top:0;
    left:0;
    height:70px;
    width:70px;
    background:$white;
    animation: fade 1.5s;
    svg {transform:scale(0.4)}
    path {
        fill: #000;
        fill-rule: evenodd;
     }
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
}






// TOP LINE NAMES

.bar-top {
    display: block;
    height:$header-height-tablet;
    line-height: $header-height-tablet;
    position:absolute;
    transform:translate3d(0px, 0px, 0px);
    transition: transform 0.3s;
    top:0px;
    right:0px;
    width:100%;
    p {
        padding-left: 30px;
        text-transform: uppercase;
        font-size:9px;
        color:$white;
        letter-spacing: 2px;
        display: inline-block;
        vertical-align: middle;
        line-height: normal!important;
    }
    &::after {content:none;}
    &.l1 {
        background:$bg-l1-top;z-index: 1011;
    }
    &.l2 {
        background:$bg-l2-top;z-index: 1013;
    }
    &.l3 {
        background:$bg-l3-top;z-index: 1015;
    }
    &.l2, &.l3 {
        transform:translate3d(100%, 0px, 0px);
    }
    @include brk($m) {
        height:$header-height-mobile;
        line-height: $header-height-mobile;
    }
}







#nav_mobile .social-icons.mobile {
    height:60px;
    width:100%;
    margin-right: 10px;
    display:none;
    background:#dcdcdc;
    padding-top: 12px;
    padding-left:20px;
    @include brk ($t) {display:inline-block;}
    .icon {
        border-radius:50%;
        width:24px;
        height:24px;
        float: left;
        animation: fade_scale 1.2s;
        margin-left: 6px;
        margin-right: 10px;
        margin-top: 6px;
        path {
            transition: fill 0.3s;
            &.st0 {
                fill: #222d62;
                &:hover {
                    fill: darken(#222d62, 10%);
                }
            }
        }
        path.st1, rect {
            fill:#ede4d6
        }
        svg { transform:scale(1.25) }
    }
}







// SEARCH ICON ----------------------------------


#nav_mobile .icon-search-container {
    position: absolute;
    top:0;
    left:0;
    height:$header-height-tablet;
    width:$header-height-tablet;
    background:#06a0c3;
    z-index: 1012;
    .icon {
        margin-top:5px;
        width: 40px;
        height: 40px;
        transition: transform 0.5s, background 0.6s;
        svg {transform:scale(0.55)}
        path {fill:#fff;}
    }
}













// WPML -----------------------------------------------------


.wpml-ls-statics-footer.wpml-ls-legacy-list-horizontal {
    position: relative;
    padding: 15px 16px 5px;
    overflow: hidden;
    z-index: 1012;
    animation: fade 2s;
    background: $bg-l1-robe;
    &:before {
        content:"";
        display: inline-block;
        width:100%;
        height:2px;
        position: absolute;
        top:0;
        left:0;
        right:0;
        background: darken($touche1, 20%);
    }

    >ul>li {
        display: inline-block;
        position: relative;
    }
    >ul>li>a {
        display: inline-block;
        height:100%;
        color:#fff;
        font-size: 11.5px;
        font-weight: $bold;
        font-family: $family1;
        padding: 11px 14px;
        text-transform: uppercase;
        img {
            width:16px;
            height:auto;
        }
    }
}


