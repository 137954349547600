



.archive.videos .banner.top {
    position: fixed;
    top:$header-height;
    left:0;
    right:0;
    background: lighten(#041a26, 5%);
    height:60px;
    z-index: 100;
    background:darken(#042031, 5%);
    @include brk($t) { top:$header-height-tablet; }
    @include brk($m) { top:$header-height-mobile; }
}







.archive.videos .banner.medium {
	// background: #fff;
	padding: 65px 0 160px;
	min-height:200px;
	margin-top:-5px;
	background:linear-gradient(to bottom, lighten(#072f44, 0%) 0%, lighten(#072f44, 0%) 85%, transparentize(#092636, 1) 100%);
	@include brk ($t) { padding: 50px 0 110px; }
	@include brk ($m) { padding: 0px 0 0px; }
	.banner-content {
		@include brk($t) {padding:0!important;}
	}
	.col-left {
		padding-right: 2.5%;
		width:22%;
		float:left;
		text-align:right;
		min-height:660px;
		.side-container {
            position: fixed;
            // left:calc(50% - 600px);
            top:170px;
            width:19%;
            @include brk(1320px) {
            	left:calc(6% - 18px);
            }
            @include brk($t) {
            	top:136px;
            }
            @include brk($td) {
                left:calc(50% - 450px);
            }
            @include brk($tp) {
                left:calc(50% - 400px);
            }
            .icon.coat-of-arms {
                width:580px;
                height:580px;
                margin-left: -88px;
                display: inline-block;
                float: left;
                opacity: 0.3;
                .st0{fill:darken(#042031, 0%);}
                @include brk($tp) {display:none}
            }
        }
        @include brk($tp) {display:none}
	}
	.col-right {
		display: block;
		width:78%;
		float:left;
		min-height:600px;
		margin:0 auto;
		background:linear-gradient(to bottom, transparentize(#000, 0.9) 0%, transparentize(#000, 0.9) 85%, transparentize(#000, 1) 100%);
		background:linear-gradient(to bottom, darken(#072f44, 3%) 0%, lighten(#072f44, 0%) 85%, transparentize(#092636, 1) 100%);
		@extend .cache-right;
		.loader {
			height:500px;
		}
		@include brk($tp) {
			width:100%;
		}
		@include brk($m) {
			background:none;
			width:100%;
			padding: 50px 6% 50px 6% !important;
		}
		>ul {
			display: inline-block;
			width: 100%;
			min-height: 1200px;
			padding: 53px 6% 80px;
			width:calc(100% + 20px);
			@include brk($td) {
				padding: 53px 6% 20px;
			}
			@include brk($m) {
				width:calc(100%);
				padding: 53px 0% 50px;
			}
			>li {
				height:300px;
				width:50%;
				float: left;
				padding-right:20px;
				position: relative;
				margin-bottom: 20px;
				@include brk($td) {
					height:250px;
				}
				@include brk($m) {
					height:auto;
					width: 100%;
					padding-right:0px;
				}
				>a {
			        .image-container {
			            position: relative;
			            width:100%;
			            height:200px;
			            float: right;
			            display: inline-block;			          
			            .image {
			            	transition: filter 0.5s;
			                animation: fade 1s;
			                filter: brightness(75%);
			            }
			            @include brk($td) {
							height:150px;
						}
			            @include brk($m) {
							height:100px;
						}
			        }
			        .infos-container {
				        background:darken(#fff, 8%);
				        transition: background 0.5s;
			            display: inline-block;
			            padding:12px 5% 35px;
			            width: 100%;
			            height:105px;
			            position: relative;
			            @include brk($m) {
							width:100%;
							height:auto;
							padding:12px 5% 15px;
						}

						.info-line {
							@include brk($m) {
								margin-bottom: 9px;
							}
				            .flag {
								width: 13px;
				            	height:auto;
				            	margin-right:9px;
				            	transform: translateY(1px);
				            	@include brk($m) {
									margin-right:4px;
								}
				            }
				            .date {
				                display: inline-block;
				                color:#777;
				                animation: fade 2.5s;
				                padding: 0px 0px;
				                margin-right: 2px;
				                cursor:pointer;
				                display: none;
				            	&:after {
				            		content:"|";
				            		color:transparentize(#000, 0.8);
				            		margin-right: 8px;
				            	}
				            }
				            .speaker {
				            	font-size: 11px;
				            	font-weight: $bold;
				            	font-family: $family2;
				            	color:#777;
				            	letter-spacing: 0;
				            	display: inline;
				            	animation: fade 2s;
				            	text-transform: uppercase;
				            	@include brk($m) {
									font-size: 9px;
								}
				            }
				            .has-caption {
								font-size: 9px;
								font-weight: $bold;
				            	font-family: $family2;
				            	background:$touche1;
				            	
				            	display: inline-block;
				            	color:#fff;
				            	padding:4px 6px 3px;
				            	float: right;
				            	margin-top: 3px;
				            	line-height: 1;
				            	text-transform: uppercase;
				            }
						}
						.title-container {
							height:65px;
							line-height:65px;
							@include brk($m) {
								height:auto;
								line-height:1;
							}
				            .title {
				                font-size: 18.5px;
				                font-weight: $bold;
				                font-family: $family2;
				                text-transform: uppercase;
				            	@extend .bloc;
				                float: none;
				                clear: both;
				                line-height: 0.95!important;
				                letter-spacing: 0px;
				                color:#555;
				                color:$touche1;
				                br {display:none;}
				                animation: fade 2.5s;
				                @include brk($t) {
									font-size: 16px;
								}
				            }
						}
			            .extrait p {
			                font-size: 16.5px;
			                line-height: 1.6;
			                animation: fade 2.5s;
			                letter-spacing: 0;
			            }
			            .extrait {
			                font-size: 15.5px;
			                line-height: 1.45;
			                animation: fade 2.5s;
			                letter-spacing: 0;
			            }
			        }
			        &:hover {
			        	.infos-container {
			        		background: #fff;
			        	}
			        	.image {
							filter: brightness(100%);
			        	}
			        }
			    }
			}
		}
	}
	
	
}










//   SEARCH FIELD ------------------------------------------------

.archive.videos .search-field {
	top:$header-height;
	position: fixed;
	height:60px;
	background:darken(#042031, 5%);
    display: inline-block;
    width:100%;
	z-index: 103;
	animation: fade 2s;
	@include brk($t) {
    	top:$header-height-tablet;
    }
    @include brk($m) {
    	top:$header-height-mobile;
    }
	@extend .cache-right;
    input {
    	height:100%;
    	color:#fff;
        width: 100%;
        padding-left: 90px;
        font-size: 15px;
        font-weight: $normal;
        // font-family: $family4;
        // letter-spacing: 2px;
        @include brk($t) {
	    	padding-left:calc(6% + 40px);
	    }
        @include brk($m) {
	    	padding-left:calc(35px);
	    }
    }
    .icon {
        position: absolute;
        width:22px;
        height:22px;
        left:51px;
        top:20px;
        svg {transform: scale(0.85)}
        path {fill:$white;fill-rule:evenodd;clip-rule:evenodd;}
        @include brk($t) {
	    	left: 6%;
	    	margin-left: -1px;
	    	svg {transform: scale(0.85)}
	    }
        @include brk($m) {
	    	left: 0%;
	    	svg {transform: scale(0.75)}
	    }
    }

    /**************  PLACEHOLDER ADJUST   ***************/
    input::placeholder {
    	
        font-size: 15px;
        font-weight: $light;
        color: #868482;
        text-align: left;
        transition: opacity 0.3s;
        @include brk($m){ font-size:14px; }
    }
    input:focus {
        &::placeholder { opacity: 0.7; }
    }

}


