


.page.euro-challenge .banner.medium {
	ul.list-videos {
		padding-top: 30px;
		padding-left: 0;
		width: 100%;
		li {
			list-style: none;
			padding-left: 0;
			&::before {
				display: none;
			}
		}
	}
}