.archive.articles {
    background:linear-gradient(to bottom, lighten(#002338, 2%) 0%, lighten(#002338, 0%) 85%, transparentize(#002338, 1) 100%);
}



.archive.articles .banner.top {
    position: fixed;
    top:$header-height;
    left:0;
    right:0;
    background:darken(#042031, 3%);
    height:60px;
    @include brk($t) { top:$header-height-tablet; }
    @include brk($m) { 
    	position: relative;
    	top:$header-height-mobile; 
    	top:0;
    	height:50px;
    }
    .col-right {
    	width:80%;
    	height:100%;
    }
}


.archive.articles .banner.medium {
	padding: 0px 0 160px;
	margin-top: 61px;
	min-height:700px;
	@include brk ($t) { padding: 0px 0 110px; }
	@include brk ($m) { 
		margin-top: 0px;
		padding: 0px 0 0px; 
		margin-top: -20px;
	}
	
	
	.banner-content {
		@include brk($m) {padding:0!important;}
	}
	.col-left {
		padding-right: 2.5%;
		width:13%;
		float:left;
		text-align:right;
		min-height:600px;
		overflow-x: hidden;
		.side-container {
            position: fixed;
            top:170px;
            width:180px;
            @include brk($tp) {
                left:calc(50% - 400px);
            }
            .icon.coat-of-arms {
                width:580px;
                height:580px;
                margin-left: -88px;
                display: inline-block;
                float: left;
                opacity: 0.5;
                .st0{fill:darken(#042031, 1.5%);}
                @include brk($td) {display:none}
            }
        }
		@include brk($td) {display:none}
	}
	.col-right {
		display: block;
		width:80%;
		float:right;
		min-height:1000px;
		// background:#fff;
		margin:0 auto;
		margin-right: 3%;
		padding-top: 0px;
		padding: 0px 6% 60px 6%;
		@keyframes show_shadow {
			0% {box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0);}
			50% {box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0);}
			100% {box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.5);}
		}
		.content {
			animation: show_shadow 1.5s;
		}
		@include brk($t) {
			margin-right: 0%;
		}
		@include brk($td) {
			width:100%;
		}
		@include brk($m) {
			background:none;
			width:100%;
			padding: 0px 6% 50px 6% !important;
		}
		.content>ul {
			position: relative;
			>li {
				margin-bottom: 0px;
				position: relative;
				margin-top: -3px;
				@include brk($td) {
					// min-height:200px;
				}
				@include brk($m) {
					height:auto;
					margin-top: 0;
					margin-bottom: 15px;
				}
				>a {
					// min-height: 160px;
			        
			        @include brk($td) {
						// min-height:200px;
					}
					@include brk($m) {
						height:auto;
					}
			        .image-container {
			        	width:180px;
			        	height:100%;
			        	display: inline-block;
			        	position: absolute;
			        	top:0;
			        	left:0;
			        	overflow: hidden;
			        	.image {
			        		background-position: 50% 20%;
			        		transition: opacity 1s, transform 0.5s;
			        	}
			        	@include brk($m) {
							width: 100%;
							height: 110px;
							position: relative;
							.image {
				        		background-position: 50% 25%;
				        	}
						}
			        }
			        .infos-container {
			        	background:rgb(94%, 93%, 91%);
			            display: inline-block;
			            padding:24px 4% 25px;
			            width: calc(100% - 180px);
			            float: right;
			            height:100%;
			            &.no-image {
			            	width:100%;
			            }
			            @include brk($m) {
							width:100%;
							padding:4px 6% 25px;
							height:auto;
						}
						.top-line {
							display: inline-block;
							width: 100%;
					        .flag {
					        	margin-top: 0px;
					        	width:15px;
					        	height:11px;
					        	width: auto;
					        	float: left;
					        	display: inline-block;
					        	margin-right: 4px;
					        }
					        ul {
					        	height:11px;
					        	display: inline-block;
					        	float: left;
					        	li {
					        		display: inline-block;
					        		transform: translate3d(0,-5px,0);
					        	}
					        }
					        .author {
								font-size: 11px;
								font-weight: $bold;
								font-family: $family2;
								line-height: 1;
					        	float: left;
					        	display: inline-block;
					        	color:#333;
								letter-spacing: 0;
								padding: 0px 2px 2px 5px;
								color:#777;
								text-transform: uppercase;
								margin-left: 6px;
								&:after {
									content: "|";
									line-height: 1;
									margin-left: 11px;
									display: inline-block;
									transform: translate3d(0,-1px,0);
									color:transparentize(#000, 0.8);
								}
								@include brk($m) {
									padding: 2px 0px;
									&:after {
										margin-left: 6px;
									}
								}
					        }
				            .date {
				                font-size: 10px;
				                font-weight: $bold;
				                font-family: $family2;
				                float: left;
				                color:#777;
				                line-height: 1;
				                padding: 1px 5px 2px 8px;
				                cursor:pointer;
				                @include brk($m) {
				                	font-size: 8px;
									padding: 3px 0px 0 5px;
								}
				            }
						}
			            .titre {
			                font-size: 19px;
			                font-weight: $bold;
			                font-family: $family2;
			                text-transform: uppercase;
			            	padding-top: 10px;
			                float: none;
			                clear: both;
			                padding-bottom:13px;
			                line-height: 1.25;
			                letter-spacing: 0px;
			                color:#555;
			                color:$touche1;
			                br {display:none;}
			                @include brk($m) {
								padding-bottom:14px;
								font-size: 16px;
							}
			            }
			            .exerpt {
			            	p {
				            	font-size: 12.5px;
				            	// font-weight: $bold;
				            	color:transparentize(#000, 0.1);
				            	line-height: 1.6;
				            	letter-spacing: 0;
			            	}
			            }
			        }
			        &:hover {
			        	.infos-container {
			        		background: white !important;
			        	}
			        	.image {
							filter: brightness(100%);
							transform: scale(1.1);
			        	}
			        }
			    }
			}
		}
	}

}












//   SEARCH FIELD ------------------------------------------------

.archive.articles .search-field {
	// top:$header-height;
	// position: fixed;
	height:100%;
	background:darken(#042031, 3%);
    display: inline-block;
    width:100%;
	z-index: 103;
	animation: fade 2s;
	@include brk($t) {
    	// top:$header-height-tablet;
    }
    @include brk($m) {
    	// top:$header-height-mobile;
    }
	@extend .cache-right;
    input {
    	height:100%;
    	color:#fff;
        width: 100%;
        padding-left: 70px;
        font-size: 15px;
        font-weight: $normal;
        background:darken(#042031, 3%);
        position: relative;
        // font-family: $family4;
        // letter-spacing: 2px;
        @include brk($t) {
	    	padding-left:calc(6% + 40px);
	    }
        @include brk($m) {
	    	padding-left:calc(35px);
	    }
    }
    .icon {
        position: absolute;
        width:22px;
        height:22px;
        left:32px;
        top:20px;
        svg {transform: scale(0.85)}
        path {fill:$white;fill-rule:evenodd;clip-rule:evenodd;}
        @include brk($t) {
	    	left: 6%;
	    	margin-left: -1px;
	    	svg {transform: scale(0.85)}
	    }
        @include brk($m) {
	    	left: 0%;
	    	top:15px;
	    	svg {transform: scale(0.75)}
	    }
    }


	/**************  PLACEHOLDER ADJUST   ***************/
    input::placeholder {
    	
        font-size: 15px;
        font-weight: $light;
        color: #868482;
        text-align: left;
        transition: opacity 0.3s;
        @include brk($m){ font-size:14px; }
    }
    input:focus {
        &::placeholder { opacity: 0.7; }
    }
}


