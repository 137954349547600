

.single.videos {
    background:linear-gradient(to bottom, lighten(#041a26, 12%) 0%, lighten(#041a26, 12%) 85%, transparentize(#092636, 1) 100%);
}


.single.videos .banner.top {
    position: fixed;
    top:$header-height;
    left:0;
    right:0;
    background: lighten(#041a26, 1%);
    height:60px;
    @include brk($t) { top:$header-height-tablet; }
    @include brk($m) { top:$header-height-mobile; }
}



.single.videos .banner.medium {
    margin-top: 60px;
    padding: 33px 0 160px;
    min-height:1200px;
    background:linear-gradient(to bottom, lighten(#072f44, 1%) 0%, lighten(#072f44, 0%) 85%, transparentize(#092636, 1) 100%);
    @include brk ($t) { padding: 33px 0 110px; }
    @include brk ($tp) { padding: 4px 0 110px; }
    @include brk ($m) { padding: 60px 0 0px; }
    .banner-content {
        @include brk($m) {padding:0!important;}
    }
    .col-left {
        padding-right: 0%;
        width:17%;
        float:left;
        min-height:200px;
        display: inline-block;
        text-align:right;
        @include brk($l) {
        }
        @include brk($t) {
            min-height:100px;
        }
        @include brk($m) {display:none}
        .side-container {
            position: fixed;
            // left:calc(50% - 600px);
            top:108px;
            width:280px;
            @include brk(1320px) {
                left:calc(6% - 18px);
            }
            @include brk($t) {
                top:74px;
            }
            @include brk($tp) {
                left:calc(50% - 400px);
            }
            .icon.coat-of-arms {
                width:580px;
                height:580px;
                margin-left: -88px;
                display: inline-block;
                float: left;
                opacity: 0.3;
                .st0{fill:darken(#042031, 0%);}
                @include brk($tp) {display:none}
            }
            
        }
        .btn-retour {
            height:60px;
            padding-left: 100px;
            width: 165px;
            animation: fade 1s;
            // background: $touche2;
            display: inline-block;
            float: left;
            position: relative;
            z-index: 1000;
            @extend .cache-left;
            @extend .cache-right;
            .icon {
                position: absolute;
                height:14px;
                width:14px;
                left:68px;
                top:27px;
                transform: rotate(180deg);
                transition: transform 0.3s;
                .st0 {
                    fill:#fff;
                }
            }
            p {
                text-align: right;
                color:#fff;
                text-transform: uppercase;
                padding-right: 40px;
                padding-top: 24px;
                font-size: 10px;
                font-weight: $bold;
                letter-spacing: 1.5px;
            }
            @include brk($m) {
                width: 79px;
                p {display:none;}
            }
            &:hover {
                background:darken(#042031, 5%);
                .icon {
                    transform: rotate(180deg) translate3d(3px,0,0);
                }
            }
        }
    }
    .col-right {
        display: inline-block;
        width:870px;
        margin-right: 5%;
        float:right;
        min-height:600px;
        margin-top: -60px;
        background:darken(#fff, 8%);
        background:lighten(#042031, 0%);
        box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.5);
        position: relative;
        @include brk($l) {
            margin-right: 0%;
        }
        @include brk($t) {
            width:700px;
            margin-right: 4%;
        }
        @include brk($td) {
            width:680px;
            margin-right: 0%;
        }
        @include brk($tp) {
            margin-left: 0%;
            width:100%;
            padding: 0px 0% 35px !important;
        }
        // @extend .cache-right;
        &:after {
            content:"";
            display: inline-block;
            width:calc(100% - 165px);
            height:89px;
            background:lighten(#042031, 0%);
            position:absolute;
            bottom:0;
            right:0;
        }
        .top-video {
            iframe {
                width: 100%;
                height: 489px;
                @include brk($l) {height: 489px;}
                @include brk($t) {height: 394px;}
                @include brk($td) {height: 383px;}
                @include brk($tp) {height: 381px;}
                @include brk($m) {height: 320px;}
                @include brk($sm) {height: 200px;}
            }
        }
        .content {
            display: inline-block;
            width: 100%;
            padding: 20px 7% 0px 6%;
            color:#555;
            animation: fade 2s;
            margin-bottom: 60px;
            .infos-container {
                position: relative;
                display: inline-block;
                width:100%;
                padding-bottom: 14px;
                border-bottom:1px solid transparentize(#fff, 0.85);
                width: 100%;
                margin-bottom: 40px;
                @include brk($m){
                    margin-bottom: 30px;
                }
                .info-line {
                    margin-bottom: 14px;
                    height:20px;
                    .inside {
                        animation: fade 1s;
                    }
                    p {
                        font-size: 12px;
                        font-weight: $bold;
                        font-family: $family2;
                        display: inline-block;
                        color:#777;
                        text-transform: uppercase;
                    }
                    .flag {
                        width: 13px;
                        height:auto;
                        margin-right:9px;
                        transform: translateY(1px);
                    }
                    .date {
                        margin-right: 4px;
                        cursor:pointer;
                        &:after {
                            content:"|";
                            color:transparentize(#fff, 0.85);
                            margin-left: 8px;
                        }
                    }
                    .speaker {

                    }
                    .duration {
                        &:before {
                            content:"|";
                            color:transparentize(#fff, 0.85);
                            margin-left: 5px;
                            margin-right: 9px;
                        }
                    }
                }
                .titre {
                    font-size: 29px;
                    font-weight: $bold;
                    font-family: $family2;
                    text-transform: uppercase;
                    padding-top: 10px;
                    float: none;
                    clear: both;
                    padding-bottom:13px;
                    line-height: 1.2;
                    letter-spacing: 0.2px;
                    color:#555;
                    color:$touche1;
                    br {display:none;}
                    @include brk($m) {
                        font-size: 18px;
                    }
                }
            }
            .description {
                min-height: 100px;
                margin-bottom: 100px;
                * {
                    color:transparentize(#fff, 0.1);
                    font-weight: $light;
                    letter-spacing: 0;
                }
                .youtube-description {
                    animation: fade 1s;
                    font-size: 14px;
                }
            }
        }
    }
}









$wpml_second_height: 50px;



.single.videos .wpml-ls-legacy-dropdown {
    height: $wpml_second_height;
    position: absolute; 
    display: inline-block;
    right:-23px;
    top:-9px;
    @include breakpoint ($menu-off) {
        display: none;
    }
    >ul {
        height:$wpml_second_height;
    }
    a {
        font-size: 12px;
        font-weight: $light;
        color: transparentize(#000,0.1);
        font-family: $family1;
        img {
            width:19px;
            height:auto;
        }
    }
    >ul>li>a {
        height:$wpml_second_height;
        padding:  10px 16px 10px 12px;
        position: relative;
        img {
            margin-top: 5px;
            margin-right: 8px;
        }
        &::after {
            content:"";
            width:8px;
            height:8px;
            position:absolute;
            top:20px;
            right:8px;
            background-size:contain!important;
            background: url('../assets/images/wpml_down_blue.svg')
        }
        span {
            position: relative;
            display: none;
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        background: #fff;
        border: 1px solid transparentize(#000, 0.9);
        box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.5);
        position:absolute;
        // height:100%;
        top:47px;
        left:-8px;
        z-index: 100;
    }
    >ul>li>ul>li {
        width: 176px;
        // background:#eeeeee;
        display: block;
        margin-bottom: 0px;
        // &:hover {background:$white;}
        &:first-child>a { padding-top: 14px!important; }
        &:last-child>a { padding-bottom: 9px!important; }
    }
    >ul>li>ul>li>a {
        // box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        display: inline-block;
        // height:100%;
        text-align: center;
        padding:3px 10px 3px 20px;
        img {
            margin-top: 4px;
            margin-right: 7px;
            float: left;
            filter: brightness(90%);
        }
        span {
            display: inline-block;
            float: left;
            color:transparentize(#000, 0.1);
        }
        &:hover {
            img {
                filter: brightness(100%);
            }
            span {
                color:transparentize(#000, 0);
            }
        }
    }
}






















.single.videos .sharing-module {
    display:inline-block;
    padding-top:20px;
    height: 60px;
    width:100%;
    animation: fade 2s;
    text-align:left;
    position: relative;
    margin-top: 15px;
    border-top:1px solid transparentize(#fff, 0.9);
    &.bottom {
        padding-top:0px;
        border-top:none;
        height: 100px;
        margin-bottom: 40px;
        border-bottom:1px solid transparentize(#fff, 0.9);
    }
    @include brk($t) {
        top:auto;
        left: auto;
        position: relative;
        margin-top: 20px;
        text-align:left;
        height: 65px;
    }
    @include brk($t) {
        margin-top: 0px;
    }
    .icon {
        height: 46px;
        width: 46px;
        margin-bottom: 18px;
        // border:1px solid rgba(0,0,0,0.15);
        cursor: pointer;
        // background:rgba(0,0,0,0.15);
        transition: border 0.3s;
        border-radius:50%;
        background:transparentize(#000, 0.5);
        @include brk($t) {
            margin-right: 13px;
        }
        svg {transform:scale(1.0); transition: transform 0.8s}
        .st0 {
            // transition: fill 1s;
            fill:$touche2;
            fill:#fff;
        }
        &:hover {
            background:$touche1;
            // border:1px solid $touche2;
            svg {
                // transform:scale(1.15);
            }
            .st0 {
                fill:#fff;
                // fill:$touche2;
            }
        }
    }
}
































// DISQUS COMMENTS -------------------------------------


#comments {
    display: none;
}

.fb_iframe_widget {
    width:100%;
}

#comments-evolved-tabs{
    margin-top: 20px!important;
    position: relative;
    &:after {
        content:"";
        display: inline-block;
        width:100%;
        height:53px;
        background:lighten(#042031, 0%);
        position:absolute;
        bottom:0;
        left:0;
    }
    >ul>li {
        outline: none;
    }
    #comments-evolved-disqus-tab {
        display: inline-block;
        width:100%;
        height:auto!important
    }
}

._5lm5._2pi3._3-8y{
    display: none!important;
}
