

.single.articles {
    background:linear-gradient(to bottom, lighten(#002338, 2%) 0%, lighten(#002338, 0%) 85%, transparentize(#002338, 1) 100%) !important;
}


.single.articles .banner.top {
    position: fixed;
    top:$header-height;
    left:0;
    right:0;
    background: lighten(#041a26, 5%);
    height:60px;
    @include brk($t) { top:$header-height-tablet; }
    @include brk($m) { top:$header-height-mobile; }
}



.single.articles .banner.medium {
    padding: 140px 0 160px;
    min-height:1200px;
    @include brk ($t) { padding: 20px 0 110px; }
    @include brk ($ml) { padding: 0 0 0px; }
    .banner-content {
        @include brk($m) {padding:0!important;}
    }
    .col-left {
        padding-right: 0%;
        width:18%;
        float:left;
        min-height:200px;
        display: inline-block;
        text-align:right;
        .side-container {
            position: fixed;
            top:168px;
            width:180px;
            @include brk($tp) {
                left:calc(50% - 400px);
            }
            .icon.coat-of-arms {
                width:580px;
                height:580px;
                margin-left: -88px;
                display: inline-block;
                float: left;
                opacity: 0.5;
                .st0{fill:darken(#042031, 1.5%);}
                @include brk($tp) {display:none}
            }
            .btn-retour {
                height:60px;
                width: 145px;
                background: $touche2;
                display: inline-block;
                float: right;
                margin-right: 21px;
                position: relative;
                @extend .cache-left;
                @extend .cache-right;
                .icon {
                    position: absolute;
                    height:14px;
                    width:14px;
                    left:22px;
                    top:26px;
                    transform: rotate(180deg);
                    transition: transform 0.3s;
                    .st0 { fill:#fff; }
                }
                p {
                    color:#fff;
                    text-transform: uppercase;
                    padding-right: 40px;
                    padding-top: 24px;
                    font-size: 10px;
                    font-weight: $bold;
                    letter-spacing: 1.5px;
                }
                @include brk($tp) {
                    // display: none;
                    * {display:none;}
                }
                &:hover {
                    background: darken($touche2, 5%);
                    .icon {
                        transform: rotate(180deg) translate3d(3px,0,0);
                    }
                }
            }
        }
        @include brk($t) {
            min-height:100px;
        }
        @include brk($ml) {display:none}
    }
    .col-right {
        display: inline-block;
        width:63%;
        float:right;
        min-height:600px;
        background:#fff;
        margin-right: 15%;
        padding: 60px 7% 35px 6%;
        box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.5);
        position: relative;
        margin-top: -110px;
        @include brk($l) {
            width:64%;
            margin-right: 13%;
        }
        @include brk($t) {
            margin-top: -100px;
            width:85%;
            margin-right: 0%;
        }
        @include brk($tp) {
            width:100%;
        }
        @include brk($ml) {
            margin-left: 0%;
            width:100%;
            padding: 0px 0% 35px 0% !important;
            margin-top: 0;
        }
        &:after {
            content:"";
            display: inline-block;
            width:calc(100% - 165px);
            height:89px;
            background:#fff;
            position:absolute;
            bottom:0;
            right:0;
        }
        .top-container {
            position: relative;
            display: inline-block;
            width:100%;
            @extend .clearfix;
            .image-container {
                display: inline-block;
                height:100%;
                width:150px;
                top:0;
                left:0;
                position: absolute;
            }
            .infos-container {
                width: calc(100% - 150px);
                float: right;
                border: 1px solid transparentize(#000, 0.9);
                padding: 10px 25px 10px 25px;
                &.no-image {
                    width:100%;
                }
                .top-line {
                    margin-top: 0px;
                    img {
                        width:16px;
                        display: inline-block;
                        margin-right: 5px;
                        transform: translateY(2px);
                    }
                    .date {
                        font-size: 11px;
                        font-weight:$bold;
                        font-family: $family2;
                        display: inline-block;
                        color:#999;
                        margin-bottom: 12px;
                        padding: 6px 0px;
                        @include brk($m) {
                            margin-bottom: 0;
                        }
                    }
                    .translations {
                        position: absolute;
                        top:11px;
                        right:16px;
                        text-align: right;
                        span {
                            font-size: 7px;
                            font-weight:$bold;
                            font-family: $family2;
                            text-transform: uppercase;
                            color:#fff;
                            letter-spacing: 1px;
                            background: transparentize(#000, 0.5);
                            display: inline-block;
                            padding: 3px 5px;
                            cursor:default;
                            transform: translateY(-1px);
                        }
                        ul, li { display: inline-block; }
                        img {
                            width: 15px;
                            margin-right: -1px;
                            opacity: 0.5;
                        }
                        li:hover img { opacity: 1;}
                        @include brk($m) {
                            position: relative;
                            top: auto;
                            right: auto;
                            text-align: left;
                            margin-bottom: 15px;
                        }
                    }
                }
                .title-container {              
                    .title {
                        font-size: 28px;
                        font-weight: $bold;
                        font-family: $family2;
                        text-transform: uppercase;
                        float: none;
                        clear: both;
                        padding-top: 10px;
                        padding-bottom:25px;
                        line-height: 1!important;
                        letter-spacing: 0px;
                        color:#555;
                        color:$touche1;

                        br {display:none;}
                        @include brk($m) {
                            padding-bottom:25px;
                            font-size: 18px;
                        }
                    }
                }
                .by-container {
                    margin-bottom: 0px;
                    .author, .translator, .source, .translations {
                        font-size: 11px;
                        letter-spacing: 0;
                        font-weight: $bold;
                        span {
                            font-weight: $normal;
                            margin-right: 2px;
                        }
                    }
                    .source-container {
                        display: inline-block;
                        width: 100%;
                        margin-bottom:3px;
                        @include brk($t) {
                            // margin-bottom:10px;
                        }
                        >p {
                            font-size: 11px;
                            letter-spacing: 0;
                            font-weight: $normal;
                            margin-right: 8px;
                            display: inline-block;
                            float: left;
                        }                    
                        .source {
                            position: relative;
                            display: inline-block;
                            padding-right: 10px;
                            padding-bottom: 5px;
                            width:calc(100% - 50px);
                            float: left;
                            font-size: 10px;
                            color:transparentize(#000, 0.65);
                            letter-spacing: 0;
                            line-height: 1.1;
                            transform: translateY(4px);
                            &:after {
                                // content:"";
                                // width: calc(100% - 11px);
                                // height:1px;
                                // background:$touche1;
                                // position: absolute;
                                // left:0;
                                // bottom:7px;
                            }
                            &:hover {
                                color:transparentize(#000, 0);
                                &:after {
                                    height:2px;
                                    bottom:6px;
                                }
                                .source-tooltip {
                                    // display: block;
                                }
                            }
                        }
                        .source-tooltip {
                            position: absolute;
                            top: 5px;
                            left:calc(100%);
                            font-size: 8px;
                            font-weight:$normal;
                            color:#fff;
                            background: transparentize(#000, 0.5);
                            display: none;
                            padding: 5px 8px;
                            &:hover {
                                background: transparentize(#000, 0.3);
                            }
                        }
                    }
                }
            }
        }

        .bottom-container {
            @include brk($ml) {
                padding: 0px 6% 0px 6% !important;
            }
            .content {
                color:#555;
                animation: fade 2s;
                margin-top: 25px;
                margin-bottom: 60px;
                padding-top:30px;
                @include brk($m) {
                    margin-top: 15px;
                }


                ul.list-videos {
                    padding-top: 65px;
                    padding-left: 0;
                    width: 100%;
                    li {
                        list-style: none;
                        padding-left: 0;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}











.single.articles .logo-aemn-container {
    margin-top: 100px;
    display: inline-block;
    position: relative;
    height: 100px;
    width: 200px;
}






.single.articles .sharing-module {
    display:inline-block;
    padding-top:20px;
    height: 60px;
    width:100%;
    animation: fade 2s;
    text-align:left;
    position: relative;
    margin-top: 15px;
    border-top:1px solid transparentize(#000, 0.9);
    &.top {
        display: none !important; 
    }
    &.bottom {
        padding-top:0px;
        border-top:none;
        height: 100px;
        margin-bottom: 40px;
        border-bottom:1px solid transparentize(#000, 0.9);
    }
    @include brk($t) {
        top:auto;
        left: auto;
        position: relative;
        margin-top: 20px;
        text-align:left;
        height: 65px;
    }
    @include brk($t) {
        margin-top: 0px;
    }
    .icon {
        height: 46px;
        width: 46px;
        margin-bottom: 18px;
        border:1px solid rgba(0,0,0,0.15);
        cursor: pointer;
        // background:rgba(0,0,0,0.15);
        transition: border 0.3s;
        border-radius:50%;
        @include brk($t) {
            margin-right: 13px;
        }
        svg {transform:scale(1.0); transition: transform 0.8s}
        .st0 {
            // transition: fill 1s;
            fill:$touche2;
        }
        &:hover {
            background:$touche2;
            border:1px solid $touche2;
            svg {
                // transform:scale(1.15);
            }
            .st0 {fill:#fff;}
        }
    }
}















// DISQUS COMMENTS -------------------------------------


#comments {
    display: none;
}

.fb_iframe_widget {
    width:100%;
}
#comments-evolved-tabs{
    margin-top: 20px!important;
    position: relative;
    &:after {
        content:"";
        display: inline-block;
        width:100%;
        height:53px;
        background:#fff;
        position:absolute;
        bottom:0;
        left:0;
    }
    >ul>li {
        outline: none;
    }
    #comments-evolved-disqus-tab {
        display: inline-block;
        width:100%;
        height:auto!important
    }
}

._5lm5._2pi3._3-8y{
    display: none!important;
}
