

.lightbox-video-all-container {
    display: none;
    &.active {
        display: block;
    }
}


$lightbox_container_opacity: 0.9;

.page-overlay.video {
    position: fixed;
    display: block;
    opacity:$lightbox_container_opacity;
    z-index:1999;
    &.ng-enter {
        opacity: 0;
        transition: opacity 0.3s;
        &.ng-enter-active { opacity: $lightbox_container_opacity; }
    }
    &.ng-leave {
        opacity: $lightbox_container_opacity;
        transition: opacity 0.35s;
        &.ng-leave-active { opacity: 0; }
    }
}




.lightbox-container.video {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:2000;
    overflow-y: scroll;
    width: calc(100% + 19px);
    cursor: pointer;
    @include brk ($t) {
        width: calc(100% + 25px);
    }
}

$width_lightbox_personne_desktop: 860px;
$width_lightbox_personne_tablet: 720px;
$width_lightbox_personne_mobile: 320px;

.lightbox.video {
    position: absolute;
    margin-top:110px;
    left:calc(50% - 3px);
    margin-left: - calc($width_lightbox_personne_desktop / 2);
    max-width:$width_lightbox_personne_desktop - 0px;
    z-index: 1000;
    display: inline-block;
    // width:860px;
    min-height:220px;
    // background:#ffffff;
    overflow:hidden;
    margin-bottom: 120px;
    transition: top 1s;
    cursor: default;
    background:#777;
    &.ng-enter {
        transition: transform 0.5s, opacity 0.3s; 
        transform:scale(0.85); opacity:0;
    }
    &.ng-enter-active {transform:scale(1); opacity:1}
    @include breakpoint ($tablet) {
        margin-top:0px;
        margin-left: - calc($width_lightbox_personne_tablet / 2);
        max-width:100%;
        top:100px;
        width:$width_lightbox_personne_tablet;
        // left:calc(50% - 3px);
        // margin-left: 0;
    }
    @include breakpoint ($mobile) {
        top:100px;
        margin-left: 0;
        left:5%;
        width:90%;
        // max-width:$width_lightbox_personne_mobile - 20px;
        // position: absolute;
    }
    >.inside {
        background: rgba(98%,96%, 93%, 1);
        display: block;
        // padding: 80px 9% 50px;
        width:$width_lightbox_personne_desktop;
        min-height:600px;
        @include brk($t) {
            width:100%;
        }
        // @include brk($m) {
        //     padding: 75px 9% 10px;
        // }
        @extend .format;
        >p {
            @include brk($m) {
                // text-align: center;
            }
        }
        >p>a {
            padding-top:0;
            margin:0;
            position: relative;
            &:before {
                content:"";
                display: inline-block;
                position: absolute;
                width:100%;
                height:1px;
                bottom:2px;
                left:0px;
                background: lighten(#000, 65%);
            }
            &:after {display:none;}
            &:hover {
                &:before {
                    height:2px;
                    bottom:1px;
                }
            }
        }
        .col-video {
            width: 100%;
            display: inline-block;
            filter: brightness(90%);
            position: relative;
            transition: height 1s;
            height:485px;
            // @include brk($l) {height:322px;}
            // @include brk($t) {height:280px;}
            // @include brk($td) {height:242px;}
            // @include brk($tp) {height:180px;}
            // @include brk($m) {height:215px;}
            @include brk($t) {
                height:406px;
            }
            @include brk($m) {
                height:200px;
            }
            .youtube-frame {
                position: relative;
                display: block;
                height: 100%;
                iframe {
                    margin: 0 auto;
                    display: block;
                    height:100%;
                    width:100%;
                }
                @include brk($m) {
                    // height:auto;
                    width:100%!important;
                }
            }
        }
        .col-infos {
            display: inline-block;
            padding:30px 6.5% 45px;
            width: 100%;
            .date {
                display: inline-block;
                width:100%;
                color:#444;
                font-size: 13.5px;
                animation: fade 2.5s;
                cursor:pointer;
            }
            .titre {
                cursor:pointer;
                padding-bottom:15px;
                line-height: 1.4;
                font-size: 21px;
                font-weight: $bold;
                letter-spacing: 0.2px;
                margin-bottom: 0px;
                br {display:none;}
                animation: fade 2.5s;
            }
            .extrait p {
                font-size: 16.5px;
                line-height: 1.6;
                animation: fade 2.5s;
                letter-spacing: 0;
            }
            .extrait {
                font-size: 16.5px;
                line-height: 1.65;
                animation: fade 2.5s;
                letter-spacing: 0;
            }
            .partager-sur-facebook {
                user-select:none;
                color:#fff;
                background:rgb(11%, 20%, 42%);
                font-size: 15px;
                margin: 0 auto;
                height:38px;
                margin-top: 45px!important;
                margin-bottom: 0px;
                padding:10px 15px!important;
                font-weight: $light;
                text-align: center;
                font-style:italic;
                display:inline-block;
                display: none;
                &:hover {background:rgb(21, 39, 81)}
                &.article {
                    margin-top: 80px;
                    display: inline-block;
                    margin-right: 20px;
                }
            }
        }
    }
    

    .close-btn {
        display: inline-block;
        width:60px;
        height:60px;
        position: absolute;
        z-index: 1100;
        top:10px;
        right:10px;
        cursor:pointer;
        // background:#777;
        animation: fade 1.5s;
        
        .icon {
            display: block;
            width:24px;
            height:24px;
            margin: 0 auto;
            margin-top: 22px;
            .st0{stroke:$touche1;stroke-width:2.5;stroke-miterlimit:10;}
        }
        @include breakpoint ($mobile) {
            height:60px;
            width:60px;
        }
        &:hover {
            transition: background 1.8s;
            .st0{fill:none;stroke-width:4.5;stroke-miterlimit:10;}
        }
   }
    
}
