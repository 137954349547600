.front-page {
    
    // background:#f6f3ef;

    .col-left, .col-right {
        animation: fade 2s;
    }
    .col-left {
        padding-right:10%;
        @include brk($t) {
            padding-right:0%;
        }
    }
    .col-right {
        padding-left:0px;
    }
    
    .btn {
        // background: rgba(0,0,0,0.7);
        // border: 1px solid #666;
        padding:10px 20px;
        margin-top: 40px;
        font-size:12px;
        color:#fff; 
        &:hover {
            // background:darken(#0e190d, 10%);
            // border: 1px solid darken(#0e190d, 10%);
        }
    }


}





// ACCUEIL HEIGHT BANNER TOP

$pgac_bT_h-desktop: 1100px;
$pgac_bT_h-laptop: 1100px;
$pgac_bT_h-tablet: 950px;
$pgac_bT_h-mobile: 650px;




.front-page .banner.top {
    background: lighten(#002338, 0%);
    transition: height 1s;
    height:$pgac_bT_h-desktop;
    @include brk ($l) { height:$pgac_bT_h-laptop; }
    @include brk ($t) { height:$pgac_bT_h-tablet; }
    @include brk ($m) { height:$pgac_bT_h-mobile; }
}
.front-page .banner.top .bloc-container {
    transition: line-height 1s;
    // @include brk ($l) { line-height:$pgac_bT_h-laptop; }
    // @include brk ($t) { line-height:$pgac_bT_h-tablet; }
    // @include brk ($m) { line-height:$pgac_bT_h-mobile; }
}



.front-page {
    .image.mountain {
        height:calc(100% - 380px);
        height:863px;
        top:300px;
        bottom: 0px;
        z-index: 1;
        // animation: fade 1.2s; 
        background-position: 50% 25%;
        @include brk($m) {height: calc(100%)}
    }
    .sky.gradient {
        background:linear-gradient(to bottom, lighten(#002338, 2%) 0%, lighten(#002338, 0%) 85%, transparentize(#002338, 1) 100%);
        height:540px;
        position: absolute;
        top:0;
        left:0;
        right:0;
        z-index: 2;
        @include brk($m) {
            height: 380px;
        }
    }
}







.front-page .banner.top {
    overflow:hidden;

    .intro {
        // background:linear-gradient(to bottom, lighten(#041a26, 10%) 0%, lighten(#092636, 6%) 85%, transparentize(#092636, 1) 100%);
        height:auto;
        position: relative;
        z-index: 3;


        // float: left;
        // padding-left:0;
        // padding-right:0;
        // padding-top:50px;
        // width:22%;

        
        .bloc-container {
            line-height:1;
            // display: none;
            @extend .clearfix;
            @include brk($l) {height:530px;}
            @include brk($t) {height:450px;}
            @include brk($m) {height:630px;}
            li {
                z-index: 2;
            }
            .banner-content {}
            .bloc {
                text-align: left;
                padding: 165px 10% 0% 22%;
                width:100%;
                float: right;

                // float: left;
                // padding-left:0;
                // padding-right:0;
                // padding-top:0px;
                

                @include brk($l) {padding: 130px 15% 0 20%;}
                // padding:80px 10% 0;
                @include brk($tablet) {
                    padding:120px 0% 0;
                    float: left;
                }
                @include brk($tablet) {
                    padding:120px 6% 0;
                }
                @include brk($m) {
                    padding:60px 6% 0;
                }
                h1.titre {
                    font-size: 44px;
                    font-weight: $bold;
                    font-family: $family2;
                    clear: both;
                    animation: fade 1s;
                    color:#fff;
                    letter-spacing: 0px;
                    word-spacing: 4px;
                    margin-bottom: 30px;
                    text-transform: uppercase;
                    line-height: 1.05;
                    text-shadow: 4px 2px 8px transparentize(#000, 0.6);
                    // display: none;
                    @include breakpoint ($tablet) {
                        // font-size: 40px;
                    }
                    @include breakpoint ($mobile) {
                        font-size: 22px;
                        line-height: 1.5;
                    }
                    span {
                        color:$touche1;
                        font-weight: $light;
                    }
                }
                .chapeau p {
                    font-size: 15px;
                    font-family: $family1;
                    color: #eee;
                    letter-spacing: 0.2px;
                    line-height: 2;
                    font-weight: $light;
                    animation: fade 1.5s;text-shadow: 4px 2px 6px  transparentize(#000, 0.5);
                    // padding: 0 12%;


                    // font-size: 13px;
                }
                .link {
                    font-weight: $bold;
                    font-family: $family2;
                    letter-spacing: 1px;
                    animation: fade 2.5s;
                    color: transparentize(#fff, 0.1);
                    transition: color 1s;
                    text-transform: uppercase;
                    border:none!important;
                    background: rgba(0,0,0,0.8)!important;
                    &:hover {
                        color:#fff;
                        background:darken(#0e190d, 10%)!important;
                    }                    
                }
                .next-event {
                    .titre {
                        font-size: 44px;
                        font-weight: $bold;
                        font-family: $family2;
                        clear: both;
                        animation: fade 1s;
                        color:#fff;
                        letter-spacing: 0px;
                        word-spacing: 4px;
                        margin-bottom: 30px;
                        text-transform: uppercase;
                        line-height: 1.05;
                        text-shadow: 4px 2px 8px transparentize(#000, 0.6);
                    }
                }
            }
        }
    }
}






$footer-color: darken(#0e190d,0%);


.front-page .banner.medium {
    z-index: 106;
    padding-bottom: 150px;
    @include brk($m) {
        padding-bottom: 50px;
    }
    .gradient {
        display: inline-block;
        height:150px;
        width:100%;
        background: linear-gradient(to bottom,
            transparentize($footer-color, 1) 0%,
            darken($footer-color,0%) 90%,
            darken($footer-color,0%));
        position: absolute;
        left: 0;
        bottom: 100%;
        @include brk($m) {
            
        }
    }
    background:linear-gradient(to bottom, darken($footer-color,0%) 0%, darken($footer-color,2%) 100%);
    .section-title {
        color:#fff;
        text-transform: uppercase;
        font-family: $family2;
        font-weight: $bold;
        font-size: 13px;
        letter-spacing: 1.5px;
        margin-bottom: 40px;
        display: inline-block;
        float: left;
        // display: none;
        // TRAIT ------------------------
        position: relative;
        &::after {
            content:"";
            position: absolute;
            top:12px;
            left:-94px;
            height:1px;
            width:70px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize(#fff, 0.8);
            display: none;
            @include brk($t) {
                display:none;
            }
        }
    }
    .focus-container {
        margin-top:100px;
        margin-bottom: 10px;
        display: inline-block;
        width: 100%;
        @include brk($m) {
            margin-top: 0;
        }
        .section-title {
        }


        .content {

            
            float: left;
            clear: both;
        }


        .image-container {
            width: 240px;
            height: 100%;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            @include brk($m) {
                position: relative;
                height: 130px;
                width: 100%;
            }
        }
        
        .texts-container {
            background:rgb(94%, 93%, 91%);
            width: calc(100% - 240px);
            padding: 30px 40px 30px;
            padding-right: 10%;
            float: right;
            @include brk($m) {
                width: 100%;
                float: none;
                padding: 22px 8% 30px !important;
            }
            .title {
                font-family: $f2;
                text-transform: uppercase;
                font-weight: $bold;
                color:$touche1;
                margin-bottom: 20px;
                @include brk($m) {
                    font-size: 20px;
                }
            }
            .text {
                p {
                    margin-bottom: 10px !important;
                }
            }

            .link {
                display: inline-block;
                float: none;
                clear: both;
                float: left;
                font-weight: $bold;
                font-family: $family2;
                letter-spacing: 1px;
                animation: fade 2.5s;
                transition: color 1s;
                text-transform: uppercase;
                border:none!important;
                margin-top: 20px;
                color: transparentize(#fff, 0.1);
                background: rgba(0,0,0,0.8)!important;
                &:hover {
                    background:darken($touche1, 0%)!important;
                    color:#fff;
                }    
                @include brk($m) {
                    float: none;
                }                
            }
        }

    }
    .latest-acticles-container {
        margin-top:100px;
        // padding-top:80px;
        position: relative;
        z-index: 100;
        // background: transparentize(#000, 0.3);
        
        
        .content>ul {
            position: relative;
            // max-width: 700px;
            display: inline-block;
            width: calc(100% + 5px);
            >li {
                display: inline-block;
                position: relative;
                line-height: 1;
                width: 50%;
                padding-right: 5px;
                float: left;
                margin-bottom: 5px;
                @include brk($td) {
                    width: 100%;
                    padding-right: 0;
                }
                >a {
                    display: inline-block;
                    width: 100%;
                    min-height:200px;
                    
                    @include brk($td) {
                        min-height:80px;
                    }
                    @include brk($m) {
                        height:auto;
                        background:rgb(94%, 93%, 91%);
                    }
                    .image-container {
                        width:180px;
                        height:100%;
                        display: inline-block;
                        position: absolute;
                        overflow: hidden;
                        top:0;
                        left:0;
                        .image {
                            transition: opacity 1s, transform 0.5s;
                            background-position: 50% 20%;
                        }
                        @include brk($m) {
                            width: 100%;
                            height: 110px;
                            position: relative;
                            float: none;
                            .image { background-position: 50% 25%; }
                        }
                    }
                    .infos-container {
                        display: inline-block;
                        padding:18px 4% 14px;
                        width: calc(100% - 180px);
                        background:rgb(94%, 93%, 91%);
                        float: right;
                        height:100%;
                        &.no-image {
                            width:100%;
                        }
                        @include brk($m) {
                            width:100%;
                            padding:4px 6% 25px;
                            height:auto;
                            float: none;
                        }
                        .top-line {
                            display: inline-block;
                            width: 100%;
                            .flag {
                                margin-top: 0px;
                                width:15px;
                                height:11px;
                                width: auto;
                                float: left;
                                display: inline-block;
                                margin-right: 4px;
                            }
                            ul {
                                height:11px;
                                display: inline-block;
                                float: left;
                                li {
                                    display: inline-block;
                                    transform: translate3d(0,-5px,0);
                                }
                            }
                            .author {
                                font-size: 11px;
                                font-weight: $bold;
                                font-family: $family2;
                                line-height: 1;
                                float: left;
                                display: inline-block;
                                color:#333;
                                letter-spacing: 0;
                                padding: 0px 2px 2px 5px;
                                color:#777;
                                text-transform: uppercase;
                                margin-left: 6px;
                                &:after {
                                    content: "|";
                                    line-height: 1;
                                    margin-left: 11px;
                                    display: inline-block;
                                    transform: translate3d(0,-1px,0);
                                    color:transparentize(#000, 0.8);
                                    @include brk($m) {
                                        margin-left: 6px;
                                    }
                                }
                            }
                            .date {
                                font-size: 10px;
                                font-weight: $bold;
                                font-family: $family2;
                                float: left;
                                color:#777;
                                line-height: 1;
                                padding: 1px 5px 2px 8px;
                                cursor:pointer;
                                @include brk($m) {
                                    font-size: 8px;
                                    padding: 2px 0px 0 5px;
                                }
                            }
                        }
                        .titre {
                            font-size: 19px;
                            font-weight: $bold;
                            font-family: $family2;
                            text-transform: uppercase;
                            padding-top: 10px;
                            float: none;
                            clear: both;
                            padding-bottom:13px;
                            line-height: 1.25;
                            letter-spacing: 0px;
                            color:#555;
                            color:$touche1;
                            br {display:none;}
                            @include brk($m) {
                                padding-bottom:14px;
                                font-size: 16px;
                            }
                        }
                        .exerpt {
                            margin-bottom: 5px;
                            p {
                                font-size: 12.5px;
                                color:transparentize(#000, 0.1);
                                line-height: 1.6;
                                letter-spacing: 0;
                            }
                        }
                    }
                    &:hover {
                        .infos-container {
                            background: white;
                        }
                        .image {
                            filter: brightness(100%);
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        .link {
            display: inline-block;
            position: absolute;
            right: 0;
            top: -7px;
            margin-top: 0;
            font-weight: $bold;
            font-family: $family2;
            letter-spacing: 1px;
            animation: fade 2.5s;
            color: transparentize(#fff, 0.1);
            transition: color 1s;
            text-transform: uppercase;
            border:none!important;
            background: rgba(0,0,0,0.8)!important;

            @include brk($m) {
                margin-top: 40px;
                position: relative;
                right: auto;
                top: auto;
            }
            &:hover {
                color:#fff;
                background:darken($touche1, 0%)!important;
            }
        }
    }
}





















.front-page .banner.news {
    margin-top: 200px;
    margin-bottom: 0px;
    position: relative;
    height:400px;
    display: none;
    // opacity: 0;
    z-index: 5;
    background: linear-gradient(to bottom,
        transparentize(#000, 1) 0%,
        transparentize(#000, 0.5) 30%,
        transparentize(#000, 0.5) 100%);
    background: linear-gradient(to bottom,
        transparentize(#fff, 1) 0%,
        transparentize(#fff, 0.1) 30%,
        transparentize(#fff, 0.1) 100%);
    // background:#fff;
    padding: 100px 10% 0 22%;
    .content {
        .titre-section {
            color:#000;
        }
    }
}











.front-page .banner.bottom.gradient {
    margin-top: -150px;
    height:150px;
    background: linear-gradient(to bottom,
        transparentize(#0e190d, 1) 0%,
        $footer-color 90%,
        $footer-color);
    z-index:105;
    @include brk($m) {
        margin-top: -270px;
    }
}


