


.page.single.demo {
	.title {
		font-size: 27.5px;
        line-height: 1.5;
        font-weight: $bold;
        color:$touche1;
        margin-bottom: 25px;
        @include brk ($m) { 
            font-size: 24px; 
            line-height: 1.35!important;
            margin-top: 10px;
        }
	}
}