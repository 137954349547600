

.lightbox-contact-all-container {
    display: none;
    &.active {
        display: block;
    }
}


$lightbox_container_opacity: 0.8;

.page-overlay.contact {
    position: fixed;
    display: block;
    opacity:$lightbox_container_opacity;
    z-index:1999;
    &.ng-enter {
        opacity: 0;
        transition: opacity 0.3s;
        &.ng-enter-active { opacity: $lightbox_container_opacity; }
    }
    &.ng-leave {
        opacity: $lightbox_container_opacity;
        transition: opacity 0.35s;
        &.ng-leave-active { opacity: 0; }
    }
}




.lightbox-container.contact {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:2000;
    overflow-y: scroll;
    width: calc(100% + 19px);
    cursor: pointer;
    @include brk($t) {
        width: calc(100%);
    }
}

$width_lightbox_personne_desktop: 860px;
$width_lightbox_personne_tablet: 720px;
$width_lightbox_personne_mobile: 320px;

.lightbox.contact {
    position: absolute;
    margin-top:110px;
    left:calc(50% - 3px);
    box-shadow: 0px 5px 59px 0px rgba(0, 0, 0, 0.5);
    margin-left: - calc($width_lightbox_personne_desktop / 2);
    max-width:$width_lightbox_personne_desktop - 0px;
    z-index: 2000;
    display: inline-block;
    width:100%;
    min-height:220px;
    overflow:hidden;
    margin-bottom: 50px;
    transition: top 1s;
    cursor: default;
    background:linear-gradient(to bottom, lighten(#041a26, 10%) 0%, lighten(#041a26, 8%) 85%, transparentize(#092636, 0) 100%);
    &.ng-enter {
        transition: transform 0.5s, opacity 0.3s; 
        transform:scale(0.85); opacity:0;
    }
    &.ng-enter-active {transform:scale(1); opacity:1}
    @include brk($t) {
        margin-top:0px;
        max-width:100%;
        top:100px;
        width:80%;
        left: 10%;
        margin-left: 0;
    }
    @include brk($m) {
        width:100%;
        top:0px;
        left:0;
        margin-bottom: 0px;
        // margin-left: - ($width_lightbox_personne_mobile / 2);
        // max-width:$width_lightbox_personne_mobile - 20px;
        // position: absolute;
    }
    >.inside {
        display: block;
        padding: 80px 9% 50px;
        @include brk($t) {
            padding: 70px 9% 35px;
        }
        @include brk($m) {
            padding: 75px 9% 10px;
        }
        @extend .format;
        >p {
            @include brk($m) {
                // text-align: center;
            }
        }
        p, a {
            color:#fff;
            line-height: 2;
            font-weight: $normal;
        }
        .contact-text a {
            padding-top:0;
            margin:0;
            position: relative;
            text-decoration: none;
            &:before {
                content:"";
                display: inline-block;
                position: absolute;
                width:100%;
                height:1px;
                bottom:3px;
                left:0px;
                background: transparentize(#fff, 0.5);
            }
            &:after {display:none;}
            &:hover {
                &:before {
                    height:2px;
                    bottom:2px;
                }
            }
        }
    }
    

    .close-btn {
        display: inline-block;
        width:60px;
        height:60px;
        position: absolute;
        z-index: 1100;
        top:10px;
        right:10px;
        cursor:pointer;
        // background:#777;
        animation: fade 1.5s;
        .icon {
            display: block;
            width:24px;
            height:24px;
            margin: 0 auto;
            margin-top: 22px;
            .st0{stroke:#fff;stroke-width:2.5;stroke-miterlimit:10;}
        }
        @include brk($m) {
            height:60px;
            width:60px;
        }
        &:hover {
            transition: background 1.8s;
            .st0{fill:none;stroke-width:4.5;stroke-miterlimit:10;}
        }
   }
    
}









.lightbox.contact {
    form {
        width:100%;
        max-width: 900px;
        margin-top: 60px;
        min-height:280px;
        margin-bottom: 45px;
        animation: fade 2s;
        @include brk($t) {
            margin-top: 30px;
            max-width: none;
        }
        @include brk($m) {margin-top: 20px;}
        .container {
            display: inline-block;
            width:100%;
            margin-bottom: 30px;
            label {
                display: inline-block;
                font-size: 17.5px;
                margin-bottom:20px;
                letter-spacing: 1px;
                color:rgba(255,255,255,0.9);
            }
            input, textarea {
                user-select: text;
            }
            input, textarea, select {
                width:100%;
                padding:0 20px;
                font-size: 15px;
                letter-spacing: 2px;
                transition: border 0.5s;
                border:1px solid rgba(255,255,255,0.1);
                // border-radius:12px;
                font-weight: $light;
                color:rgba(255,255,255,0.9);
                &:focus {
                    border:1px solid rgba(255,255,255,0.25);
                }
            }
            input, select {
                height:57px;
            }
            // FIX FOR CHROME AUTOFILL
            input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0px 1000px #1a1b1d inset;
                -webkit-text-fill-color: rgba(255,255,255,0.9) !important;
                border: transparent;
                &:focus {border: transparent;}
            }
            // &.email {width:46%;}
            &.phone {width:46%;float:right;}
            @include brk($m) {
                &.email {width:100%;}
                &.phone {width:100%;}
            }





            // input, textarea {
            //     &.ng-dirty {
            //         border-color:transparentize(orange, 0.6);
            //     }
            //     &.ng-valid {
            //         border-color:transparentize(green, 0.5);
            //     }
            //     &.ng-invalid-email {
            //         border-color:transparentize(red, 0.5);
            //     }
            // }



            input.ng-dirty, textarea.ng-dirty {
            }

            input.ng-valid {

            }






        }
        textarea {
            resize:none!important;
            padding-top:20px!important;
            box-shadow:none;
            min-height: 170px;
            height:auto;
            transition: border 0.5s, height 1s !important;
            // width: 100%;
            // display: inline-block;
            max-height:500px;
            // line-height: 2;
        }
        button[type="submit"] {
            // position: absolute;
            // bottom:50px;
            // left:15%;
            width:350px;
            background:rgba(0,0,0,0.5);
            text-transform: uppercase;
            // border-radius:12px;
            margin-top: 30px;
            font-weight: $normal;
            color:#fff;
            height:60px;
            font-size:12px;
            letter-spacing: 4px;
            transition: background 0.2s;
            @include brk($m) {width:100%;}
            &:hover, &:focus {
                background:rgba(0,0,0,0.8);;
            }
        }
    }
}





// FICHIERS --------------------------------------------


.lightbox.contact  .container.files {
    margin-bottom: 0;
    display: inline-block;
    width:100%;
    position: relative;
    min-height:10px;
    // border-bottom:1px solid rgba(0,0,0,0.10);
    // width: calc(100% - 60px);
    input {
        height:70px;
        width:100%;
        line-height: 2;
        transition: all 0.30s ease-in-out;
        @include brk ($mobile){
            height:41px;
        }
    }
    input.file::-webkit-file-upload-button {
        visibility: hidden;
    }
    input[type="file"] {
        display: none;
    }
    label, .attacher-vos-docs {
        cursor: pointer;
        display: inline-block;
        padding-top:10px;
        padding-bottom:25px;
        line-height: 1.5;
        width:100%;
        color:#fff;
        font-weight: $light;
        padding-right:80px;
        position: relative;
        &:hover + .icon-container {
            background:darken($touche1,15%);
            transform: scale(1);
        }
    }
    .icon-container {
        display: inline-block;
        width:40px;
        height:40px;
        background:$touche1;
        border-radius:50%;
        position: absolute;
        right:0px;
        top:5px;
        text-align: center;
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 0.3s;
        @include brk ($t){ top:76px; }
        @include brk ($m){ top:76px; }
        .icon {
            width:18px;
            height:18px;
            margin-top: 11px;
        }
        svg {
            transition: transform 0.3s;
            transform: scale(0.6);
        }
        .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
        &:hover {
            background:darken($touche1,15%);
            transform: scale(1);
        }
    }
    .ul-fichiers {
        margin-bottom: 15px;
        width:100%;
        li {
            display: inline-block;
            width:calc(100% - 45px);
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left:0;
            &:before {display:none;}
            cursor: pointer;
            margin-top: -4px;
            @include brk($t) {width:calc(100% - 10px);}
            &:nth-child(1) {margin-top: 20px;}
            &.ng-enter {
                @for $i from 1 through 50 {
                &:nth-child(#{$i}) { 
                    transition-delay: 0s + (0.04s * $i) - 0.04s;
                }
                }
                transition: opacity 0.7s;
                opacity: 0;
                &.ng-enter-active {
                    opacity: 1;
                }
            }
            &:hover {
                .icon-bin {
                    transform: scale(1);
                    transition: background 0.4s, transform 0.5s;
                    background: desaturate(red, 30%);
                }
                p {color:#fff;}
            }
        }
        p {
            padding: 0;
            cursor: pointer;
            text-align: left;
            transition: color 0.5s;
            padding: 0px;
            font-size: 12px;
            letter-spacing: 0px;
            font-weight: $light;
            line-height: 1.6;
            user-select:none;
        }
        .fichier-nom {
            float: left;
            width: calc(100% - 130px);
            overflow: hidden;
            position: relative;
        }
        .fichier-taille { float: right;}
        button {
            float: right;
            padding: 0 0 0 20px;
            .icon-bin {
                transform: scale(0.8);
                margin-top: -3px;
                background: #bbb;
                transition: background 0.3s, transform 0.5s;
                width:23px;
                height:24px;
                border-radius:50%;
                @include brk($td) {
                    background: desaturate(red, 30%);
                    transform: scale(0.84);
                }
                svg {transform: scale(0.4);}
                .st0{fill:none;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
            }
        }
    }
}



.lightbox.contact #progress_bar {
    width:calc(100% - 50px);
    height:1px;
    background:transparentize(#fff,0.8);
    margin-bottom: 23px;
    transition: width 1.5s;
    @include brk($t) {width:calc(100% - 10px);}
    .actual-progress {
        height:100%;
        background:#2a8128;
        width:0%;
        transition:width 0.3s;
    }
}








.lightbox.contact .response_box-container {
    position: relative;
    overflow: hidden;
    height:40px;
    // background: #eee;
    &.ng-enter {
        transition: opacity 1.5s, height 1s; 
        height:0; 
        opacity:0; 
        &.ng-enter-active {
            opacity:1; 
            height:40px; 
        }
    }
    &.ng-leave {
        transition: opacity 2.5s, height 2.5s; 
        height:40px; 
        opacity:1; 
        &.ng-leave-active {
            opacity:0; 
            height:0px; 
        }
    }
    .response_box {
        display: inline-block;
        position: absolute;
        top:0;
        left:0;
        font-size: 14px;
        width:100%;
        letter-spacing: 0px;
        font-style: italic;
        padding-left:18px;
        &.wait {color:#333}
        &.success {color:green}
        &.error {color:red}
    }
}





/**************  PLACEHOLDER ADJUST   ***************/
.lightbox.contact {
    input::placeholder, textarea::placeholder {
        
        font-size: 15px;
        font-weight: $normal;
        letter-spacing: 1px;
        color: #aaa;
        text-align: left;
        transition: opacity 0.3s;
        opacity: 1;
        @include brk($t){ font-size:15px; }
        @include brk($m){ font-size:15px; }
    }
    input:focus, textarea:focus {
        &::placeholder { opacity: 0.45; }
    }
}