










#hamburger_container {
    position: fixed;
    // background: $touche1;
    // animation: fade 1s;
    width:$header-height; 
    height:$header-height;
    z-index: 1010;
    right: 0;
    top: 0px;
    
    // display: none;
    &:hover {
        #hamburger_title {
            text-decoration: underline;
        }
    };
    &.page {
        
    }
    @include brk($t) {
        display:inline-block;
        margin-right: 0;
        width:$header-height-tablet; 
        height:$header-height-tablet;
    }
    @include brk($m) {
        width:$header-height-mobile; 
        height:$header-height-mobile;
    }
    &.is-active {
        // background:$touche1;
        path { fill:#fff !important; }
        #hamburger_title {
            // opacity: 0;
        }
    }

    button {
        height: 57px;
        width: 57px;       
        cursor:pointer; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 28.5px;
        transition: transform 0.4s;
        border: none;
        outline: none;
        display: flex;
        align-items:center;
        justify-content: center;
        // background: $touche1;
        @include brk($tp) {
        }
        @include brk($m) {
        }

        svg path { fill:#fff; }
        &.page {
            svg path { fill:#fff; }
        }
    }




    button svg {
        display: inline-block;
        width: 32px;
        height: 18px;
        position: relative;
        transition: transform 0.3s;
        rect {
            transition: transform 0.65s ease, opacity 0.25s ease;
            @include brk($m) {
                transition: transform 0.5s ease, opacity 0.25s ease;
            }
            transform: translate3d(0, 0, 0);
            &.top {
                transform-origin: 14% 0%;
            }
            &.middle {transform-origin: 50% 60%;}
            &.bottom {
                transform-origin: 14% 100%;            
            }
        }
    }


    &.is-active button {
        svg {
            rect {
                &.top {                    
                    transform: rotate(45deg) scale(1, 1);                    
                }
                &.middle {
                    opacity: 0;
                    transform: scale(0.5); 
                }
                &.bottom {
                    transform: rotate(-45deg) scale(1, 1);                    
                }
            }
        }
    }


    &.back-arrow button {
        svg {
            transform: translateX(3px);
            // transform: rotate(180deg) translate3d(5px,0,0);
            .path-container {
                transition: transform 0.2s ease, opacity 0.25s ease;
                &.top {                    
                    transform: rotate(45deg) scale(0.6, 1) translate(11px,7px);                    
                }
                &.middle {
                    transform: rotate(-45deg) scale(0.60, 1) translate(2px,-6px);                    
                }
            }
        }
    }
}
