
//  FORMAT CLASS
// ______________________________________




$format__p__font_size: 14.5px;
$format__p__font_weight: $light;
$format__p__color: darken(#202020, 0%);
$format__p__letter_spacing: 0px;
$format__p__word_spacing: 0px;
$format__p__line_height: 1.5;

$format__p__margin_top: 0px;
$format__p__margin_bottom: 15px;

$format__a__font_weight: 	$normal;
$format__a__color: 			#4a4337;
$format__a__hover_color: 	$touche1;

$format__h__font_weight : 	$bold;
$format__h__color : 		#202020;
$format__h__letter_spacing: 0.5px;
$format__h__line_height: 	1.1;
$format__h__font_family: 	$family2;
$format__h__text_transform: none;
$format__h__padding_top:   	10px;
$format__h__margin_bottom: 	25px;



* {
	// font-family:  Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-family: $family1;
}


// user-select:text;
p, li, a, h1, h2, h3, h4, h5, h6 {
	color:$grey;
}
p {
	font-size: $format__p__font_size;
	line-height: $format__p__line_height;
	color: $format__p__color;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	font-weight: $format__p__font_weight;

	margin:0;
	margin-top: $format__p__margin_top;
	margin-bottom: $format__p__margin_bottom;

	@include brk($m) {
		text-align: left!important;
		font-size: $format__p__font_size - 1px;
		margin-bottom: $format__p__margin_bottom - 5px;
	}
	span {
		-webkit-text-stroke: $format__p__color;
	}
}
blockquote {
	margin-top:0px;
	margin-bottom:40px;
	padding-left:60px;
	
	p, a {
		font-size:18px;
		font-weight:300;
		letter-spacing:0px;
		margin-bottom: 0;
		line-height:1.35;
		// text-align:center;
	}
	@include brk($td) {
        padding-left:50px;
        p,a {
			font-size: 17px;
        }
    }
	@include brk($m) {
        padding-left:40px;
        p,a {
			font-size: 16px;
        }
    }
}
a {
	font-size: $format__p__font_size;
	line-height: $format__p__line_height;
	color: $format__a__color;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	font-weight: $format__a__font_weight;
	-webkit-text-stroke: $format__a__color;
	text-decoration: underline;
	// font-style:italic;
	margin-left: 2px;
	margin-right: 2px;
	position: relative;
	&:hover {
		color: $format__a__hover_color;
	}
	// &:after {
	// 	content:"";
	// 	display: inline-block;
	// 	border-bottom: 2px solid rgba(0,0,0,0.5);
	// 	position: absolute;
	// 	height:1px;
	// 	left:0;
	// 	bottom:3px;
	// 	width:0;
	// 	opacity: 0;
	// 	transition: width 0.2s, opacity 0.8s;
	// }
	// &:hover:after {
	// 	color:#000;
	// 	width:100%;
	// 	opacity:1;
	// }
	span {
		// -webkit-text-stroke: $format__a__color;
	}
}


h2 {
	font-size:24px;
	text-transform: uppercase!important;
	padding-top:35px!important;
	margin-bottom:25px!important;
	@include brk($m) {
		font-size:20px;
		margin-bottom:20px!important;
	}
}


h2, h3, h4 {
	font-weight: $format__h__font_weight;
	color:$format__h__color;
	line-height: $format__h__line_height;
	padding-top:$format__h__padding_top;
	margin-bottom:$format__h__margin_bottom;
	letter-spacing: $format__h__letter_spacing;
	font-family: $format__h__font_family;
	text-transform: $format__h__text_transform;
}
li {
	font-size: $format__p__font_size - 1px;
	font-weight: $format__p__font_weight;
	color: $format__p__color;
	line-height:1.6;
	list-style-type:none;
	// display: table-row;
	margin-bottom:10px;
	vertical-align: top;
	padding-left:15px;
	position: relative;
	letter-spacing: $format__p__letter_spacing;
	word-spacing: $format__p__word_spacing;
	// &::after {content:"";display:table-row;height:10px;}
}
ul, ol {
	padding: 0px 0px 20px 20px;
	display:table;
}
ul {
    li:before {
        content: '›';
        color: $format__p__color;
        font-weight: $bold;
        position: absolute;
		left: 0px;
		top: 0px;
    }
}
ol {
    // list-style-position: inside;
    counter-reset: foo;
    li {
        counter-increment: foo;
        padding-left:17px;
        &::before {
        	content: counter(foo) ".";
        	position: absolute;
			left: 2px;
			top: 0px;
        }
    }
}

hr {
	border-top: 0.5px inset transparentize(#555, 0.8);
	margin-top: 8px;
	margin-bottom: 25px;
	border-left:none;
	border-bottom:none;
	border-right:none;
}

iframe {
	width:100%;
	height:auto;
}

p img {
	width:100%;
	height:auto;
}


.wp-caption {
    background: #f1f1f1;
    max-width:100%;
    margin: 10px 0 15px 0px;
    img {
    	width:100%;
    	height:auto;
    }
    p {
    	font-size: 11px;
    	font-style: italic;
    	text-align: center;
    	margin-bottom: 0;
    	color:transparentize(#000, 0.5);
    	line-height: 1;
    	padding: 10px 0;
    	margin-top: -5px;
    }
	&.alignleft {
		float: left;
		width: auto;
		margin: 10px 30px 15px 0;
	}
	&.alignright {
		float: right;
		width: auto;
		margin: 10px 0 15px 30px;
	}
	&.aligncenter {
		margin: 20px auto 30px;
		display: block;
	}
}

