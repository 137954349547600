
.page:not(.home) {
    background:linear-gradient(to bottom, lighten(#002338, 2%) 0%, lighten(#002338, 0%) 85%, transparentize(#002338, 1) 100%);
}


.page:not(.home) .banner.top {
    position: fixed;
    top:$header-height;
    left:0;
    right:0;
    background: darken(#002338, 5%);
    height:60px;
    @include brk($t) { top:$header-height-tablet; }
    @include brk($m) { top:$header-height-mobile; }
}



.page:not(.home) .banner.medium {
    padding: 140px 0 160px;
    min-height:1200px;
    .col-left {
        padding-right: 2.5%;
        width:18%;
        text-align:right;
        min-height:200px;
        float:left;
        .side-container {
            position: fixed;
            // left:calc(50% - 500px);
            top:170px;
            width:180px;
            @include brk($tp) {
                left:calc(50% - 400px);
            }
            .icon.coat-of-arms {
                width:580px;
                height:580px;
                margin-left: -88px;
                display: inline-block;
                float: left;
                opacity: 0.5;
                .st0{fill:darken(#042031, 1.5%);}
                @include brk($td) {display:none}
            }
        }
        @include brk($t) {width:14%;min-height:100px!important}
        @include brk($td) {display:none}
    }
    .col-right {
        display: block;
        float:left;
        width:67%;
        min-width: 760px;
        background:#fff;
        margin-left: 3%;
        padding-top: 10px;
        margin-top: -108px;
        padding: 40px 8.5% 70px 7.5%;
        @include brk($l) {
            // margin-left: 5%;
        }
        @include brk($t) {
            margin-left: 0%;
            margin-top: -126px;
            float:right;
        }
        @include brk($td) {
            float:none;
            width:100%;
            min-width: auto;
        }
        @include brk($m) {
            width:calc(100% - 40px);
            margin-left: 20px;
            margin-top: -104px;
            padding: 10px 6% 30px 6% !important;
        }
    }
    @include brk ($t) { padding: 160px 0 110px; }
    @include brk ($m) { padding: 135px 0 80px; }

    .flex .banner-content { padding:0!important; }


    .flex {
        margin-bottom: 30px;
        .title, .titre {
            font-size: 31.5px;
            font-weight: $bold;
            font-family: $family2;
            text-transform: uppercase;
            letter-spacing: 0;
            line-height: 1.2;
            margin-top: 15px;
            color:$touche1;
            margin-bottom: 25px;
            @include brk ($m) { 
                font-size: 20px; 
                line-height: 1.35!important;
                padding-bottom:15px;
                margin-top: 10px;
            }
        }

        .chapeau p {
            font-size: 16px;
            font-weight: $light;
            color:$touche1;
            letter-spacing: 0.2px;
            line-height: 1.8;
        }

        &.flex-titre {
            margin-bottom: 0px;         
        }

        &.flex-wysiwyg {
            margin-bottom: 40px;
        }

        &.flex-image {
            height:337px;
            margin-bottom: 25px;
            @include brk ($t) { height:290px; }
            @include brk ($m) { height:118px; }
        }
    }

    .disclaimer.about {
        padding-top: 50px; 
        margin-top: 150px; 
        border-top:1px solid rgba(0,0,0,0.1);
        .text {
            display:inline-block;
            float: right;
            width:calc(100% - 100px);
            padding-left: 30px;
            @include brk($tp) {
                width:100%;
                float: none;
                padding-left:0;
                padding-top: 20px;
                margin-bottom: 30px;
            }
            .titre-section {
                font-weight: bold; 
                margin-top: 0px; 
                margin-bottom: 5px; 
                text-transform: uppercase; 
                font-size: 13px;
                font-family: $family2;
            }
            p {
                font-size: 12px;
                letter-spacing: 0;
            }
        }

        a {
            margin-top: 3px;
            width:100px;
            height:auto;
            float: left;
            .st0{clip-path:url(#SVGID_2_);fill:#fff;stroke:#000;stroke-width:10;}
            .st1{clip-path:url(#SVGID_4_);fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
            .st2{fill:none;stroke:#000;stroke-width:0.652;stroke-miterlimit:2.613;}
            .st3{clip-path:url(#SVGID_6_);fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
            .st4{fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
            .st5{clip-path:url(#SVGID_8_);fill:none;stroke:#000;stroke-width:17.116;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;}
            .st6{clip-path:url(#SVGID_10_);}
        }
    }
}














.page:not(.home) .banner.medium {

    .banner-content {
        @include brk ($m) { padding: 0px; }
    }
    .col {
        @include brk ($t) {width:100%}
    }
    .titre {
        // font-size: 27px;
        // line-height: 1.5;
        // font-weight: $light;
    }





    .justified {
        animation: fade 3s;
        #gallery {
            width:calc(100% + 10px);
            margin-left: -5px;
        }
        a {
            transition: all 0.6s;
            cursor: pointer;
            animation-fill-mode: none !important;
            img {
                transition: filter 2s;
                filter:brightness(90%);
                @include brk($td) {
                    filter:brightness(100%);
                }
            }
            .caption {
                font-family: $f1;
                display: block;
                text-align:center;
                padding:10px 15px;
                font-size:11.5px;
                letter-spacing:1px;
                text-transform:uppercase;
                opacity: 1!important;
                background-color: transparentize($touche1, 0.1);
                transition: transform 0.3s;
                // display:none!important;
            }
            span.description {display:none;}
            &:hover {
                .caption {
                    transform: translate3d(0,100%,0);
                }
                img {
                    transition: filter 0.2s;
                    filter:brightness(100%) contrast(105%);
                }
            }
        }
    }
}



