










.page.manifesto .banner.medium {
	@include brk($m) {
		padding-top: 0px;
	}
	.col-right {
		padding-top: 80px;
		@include brk($tp) {
			padding-top: 70px;
		}
		@include brk($m) {
			padding: 0px 0 50px !important;
			margin-left: 0;
			width: 100%;
			margin-top: 0;
		}
		.top-container {
			display: inline-block;
			width: 100%;
			margin-bottom: 15px;
			position: relative;
			// @extend .clearfix;
			@include brk($tp) {
			}
			@include brk($m) {
				margin-bottom: 0px;
			}
			.image-container {
				width: 120px;
				float: left;
				// overflow: hidden;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				img {
					height: 100%;
					width: auto;
				}
				@include brk($m) {
					// width: 100px;
				}
			}
			.infos-container {
				display: inline-block;
				width: calc(100% - 120px);
				float: right;
				padding-left: 25px;
				padding-top: 0px;
				height: 100%;
				border: 1px solid transparentize(#000, 0.9);
				// text-align: center;
				// width: calc(100% - 150px);
				// float: right;
				@include brk($m) {
					padding-top: 0px;
					// width: calc(100% - 100px);
					padding-left: 20px;
				}
				.top-line {
					position: relative;
					display: inline-block;
					float: right;
					// width: 100%;
					margin-top: 10px;
					margin-bottom: 5px;
					@include brk($m) {
						width: 100%;
						margin-bottom: 0;
					}
                    img {
                        width:16px;
                        display: inline-block;
                        margin-right: 5px;
                        transform: translateY(2px);
                    }
                    >.flag {
                    	display: none !important;
                    }
                    .date {
                        font-size: 11px;
                        font-weight:$bold;
                        font-family: $family2;
                        display: inline-block;
                        color:#999;
                        margin-bottom: 12px;
                        padding: 6px 0px;
                    }
                    .translations {
                    	display: inline-block;
                        // position: absolute;
                        // top:5px;
                        // right:15px;
                        margin-right: 15px;
                        text-align: right;
                        span {
                            font-size: 7px;
                            font-weight:$bold;
                            font-family: $family2;
                            text-transform: uppercase;
                            color:#fff;
                            letter-spacing: 1px;
                            background: transparentize(#000, 0.5);
                            display: inline-block;
                            padding: 3px 5px;
                            cursor:default;
                            transform: translateY(-1px);
                            // display: none !important;
                        }
                        ul, li { display: inline-block; }
                        img {
                            width: 15px;
                            margin-right: -1px;
                            opacity: 0.5;
                        }
                        li:hover img { opacity: 1;}
                    }
                }
				.sur-title {
					padding-top: 20px !important;
					text-transform: uppercase;
					font-size: 12.5px;
					font-weight: $bold;
					font-family: $f2;
					margin-bottom: 25px !important;
					display: inline-block;
					letter-spacing: 0.5px;
					color: transparentize(#000, 0.6);
					@include brk($m) {
						padding-top: 14px !important;
						font-size: 11px;
						margin-bottom: 15px !important;
					}
				}
				.title {
					font-size: 28px;
                    font-weight: $bold;
                    font-family: $family2;
                    text-transform: uppercase;
                    float: none;
                    clear: both;
                    padding-bottom:25px;
                    line-height: 1!important;
                    letter-spacing: 0px;
                    color:#555;
                    color:$touche1;
					margin-bottom: 0px !important;
					@include brk($m) {
						padding-bottom: 0;
						margin-bottom: 10px !important;
						font-size: 24px;
					}
					@include brk ($sm) { 
		                font-size: 18px !important; 
		            }
				}
				.sub-title {
					display: inline-block;
					padding-top: 0 !important;
					font-size: 15px;
					letter-spacing: 0;
					font-family: $f2;
					font-weight: $normal;
					font-weight: $bold;
					animation: fade 1s ease 0.3s;
					margin-bottom: 20px;
					@include brk($m) {
						font-size: 13px;
					}
				}
			}
		}


        .bottom-container {
            @include brk($ml) {
                padding: 0px 6% 0px 6% !important;
            }
            .content {
                color:#555;
                animation: fade 2s;
                margin-top: 25px;
                margin-bottom: 60px;
                padding-top:30px;
                @include brk($m) {
                    margin-top: 15px;
                }
				.text {
					// border-top: 1px solid transparentize(#000, 0.9);
					padding-top: 30px;
					animation: fade 1s ease 0.5s;
					@include brk($m) {
						padding-top: 20px;
					}
				}

            }
            .related-article-container {
                margin-top: 80px;
                padding-top: 55px;
                border-top: 1px solid transparentize(#000, 0.9);

                .sub-title {
                    display: inline-block;
                    padding-top: 0 !important;
                    font-size: 15px;
                    letter-spacing: 0;
                    font-family: $f2;
                    font-weight: $normal;
                    font-weight: $bold;
                    animation: fade 1s ease 0.3s;
                    margin-bottom: 30px;
                    @include brk($m) {
                        font-size: 13px;
                    }
                }
                >a {
                    display: inline-block;
                    width: 100%;
                    min-height:180px;
                    background:rgb(94%, 93%, 91%);
                    border: 1px solid transparentize(rgb(94%, 93%, 91%), 0);
                    @include brk($td) {
                        min-height:80px;
                    }
                    @include brk($m) {
                        height:auto;
                    }
                    .image-container {
                        width:180px;
                        height:100%;
                        display: inline-block;
                        position: absolute;
                        overflow: hidden;
                        top:0;
                        left:0;
                        .image {
                            transition: transform 0.5s;
                            background-position: 50% 20%;
                        }
                        @include brk($m) {
                            width: 100%;
                            height: 110px;
                            position: relative;
                            .image { background-position: 50% 25%; }
                        }
                    }
                    .infos-container {
                        display: inline-block;
                        padding:18px 4% 14px;
                        width: calc(100% - 180px);
                        float: right;
                        height:100%;
                        &.no-image {
                            width:100%;
                        }
                        @include brk($m) {
                            width:100%;
                            padding:4px 6% 25px;
                            height:auto;
                        }
                        .top-line {
                            display: inline-block;
                            width: 100%;
                            .flag {
                                margin-top: 0px;
                                width:15px;
                                height:11px;
                                width: auto;
                                float: left;
                                display: inline-block;
                                margin-right: 4px;
                            }
                            ul {
                                height:11px;
                                display: inline-block;
                                float: left;
                                li {
                                    display: inline-block;
                                    transform: translate3d(0,-5px,0);
                                }
                            }
                            .author {
                                font-size: 11px;
                                font-weight: $bold;
                                font-family: $family2;
                                line-height: 1;
                                float: left;
                                display: inline-block;
                                color:#333;
                                letter-spacing: 0;
                                padding: 0px 2px 2px 5px;
                                color:#777;
                                text-transform: uppercase;
                                margin-left: 6px;
                                &:after {
                                    content: "|";
                                    line-height: 1;
                                    margin-left: 11px;
                                    display: inline-block;
                                    transform: translate3d(0,-1px,0);
                                    color:transparentize(#000, 0.8);
                                    @include brk($m) {
                                        margin-left: 6px;
                                    }
                                }
                            }
                            .date {
                                font-size: 10px;
                                font-weight: $bold;
                                font-family: $family2;
                                float: left;
                                color:#777;
                                line-height: 1;
                                padding: 1px 5px 2px 8px;
                                cursor:pointer;
                                @include brk($m) {
                                    font-size: 8px;
                                    padding: 2px 0px 0 5px;
                                }
                            }
                            .translations {
                                position: absolute;
                                top:11px;
                                right:16px;
                                text-align: right;
                                span {
                                    font-size: 7px;
                                    font-weight:$bold;
                                    font-family: $family2;
                                    text-transform: uppercase;
                                    color:#fff;
                                    letter-spacing: 1px;
                                    background: transparentize(#000, 0.5);
                                    display: inline-block;
                                    padding: 3px 5px;
                                    cursor:default;
                                    transform: translateY(-1px);
                                }
                                ul, li { display: inline-block; }
                                img {
                                    width: 15px;
                                    margin-right: -1px;
                                    opacity: 0.5;
                                }
                                li:hover img { opacity: 1;}
                                @include brk($m) {
                                    position: relative;
                                    top: auto;
                                    right: auto;
                                    text-align: left;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                        .titre {
                            font-size: 19px;
                            font-weight: $bold;
                            font-family: $family2;
                            text-transform: uppercase;
                            padding-top: 10px;
                            float: none;
                            clear: both;
                            padding-bottom:13px;
                            line-height: 1.25;
                            letter-spacing: 0px;
                            color:#555;
                            color:$touche1;
                            br {display:none;}
                            @include brk($m) {
                                padding-bottom:14px;
                                font-size: 16px;
                            }
                        }
                        .exerpt {
                            margin-bottom: 5px;
                            p {
                                font-size: 12.5px;
                                color:transparentize(#000, 0.1);
                                line-height: 1.6;
                                letter-spacing: 0;
                            }
                        }
                    }
                    &:hover {
                        background: lighten(rgb(93%, 92%, 90%), 15%);
                        .image {
                            filter: brightness(100%);
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
	}
}





















.page.manifesto .sharing-module {
    display:inline-block;
    padding-top:35px;
    height: 60px;
    width:100%;
    animation: fade 2s;
    text-align:left;
    position: relative;
    margin-top: 0px;
    // border-top:1px solid transparentize(#000, 0.9);
    margin-bottom: 90px;
    &.bottom {
        padding-top:0px;
        border-top:none;
        height: 100px;
        margin-bottom: 40px;
        border-bottom:1px solid transparentize(#000, 0.9);
    }
    @include brk($t) {
        top:auto;
        left: auto;
        position: relative;
        margin-top: 20px;
        text-align:left;
        height: 65px;
    }
    @include brk($t) {
        margin-top: 0px;
    }
    .icon {
        height: 46px;
        width: 46px;
        margin-bottom: 18px;
        border:1px solid rgba(0,0,0,0.15);
        cursor: pointer;
        // background:rgba(0,0,0,0.15);
        transition: border 0.3s;
        border-radius:50%;
        @include brk($t) {
            margin-right: 13px;
        }
        svg {transform:scale(1.0); transition: transform 0.8s}
        .st0 {
            // transition: fill 1s;
            fill:$touche2;
        }
        &:hover {
            background:$touche1;
            border:1px solid $touche1;
            svg {
                // transform:scale(1.15);
            }
            .st0 {fill:#fff;}
        }
    }
}









