

// LOADING BAR -------------------------------

#loading_bar {
    height:2px;
    width: 0%;
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 10000;
    transition: width 2s;
    background: transparentize($touche1, 0);
    @include brk($t) {
        background: transparentize(#fff, 0.7);
    }
    // &.home {
    //     background: transparentize(#000, 0.9);
    // }
    // &.page {
    //     // height:4px;
    //     background: transparentize(#000, 0.9);
    // }
}




.loader {
    position: absolute;
    width:100%;
    left:0;
    top:0;
    text-align: center;
    height:100%;
    // z-index:10;
    .loader-content {
        height:100%;
        width:100%;
    }

    @keyframes loader_rotate{ 
        0% {transform: rotate(0deg);} 
        100% {transform: rotate(360deg);};
    }

    .loader-circle, .loader-line-mask {
        position: absolute;
        left: calc(50% - 60px);
        top: calc(50% - 35px);
        height: 120px;
    }
        
    .loader-circle {
        width: 120px;
        border-radius: 50%;
    }
    .loader-line-mask {
        width: 60px;
        overflow: hidden;
        transform-origin: 60px 60px;
        animation: loader_rotate 1.2s infinite linear;
    }
    .loader-line-mask .loader-line {
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
}

.loader-white {
    $loader01_light_color:  rgba(255, 255, 255, 0.08);
    $loader01_heavy_color:  rgba(255, 255, 255, 0.16);
    .loader-circle, .loader-line-mask {
        top: calc(50% - 60px);
    }
    .loader-circle {box-shadow: inset 0 0 0 1px $loader01_light_color;}
    .loader-line-mask {mask-image: -webkit-linear-gradient(top, #000000, $loader01_light_color);}
    .loader-line-mask .loader-line {box-shadow: inset 0 0 0 1px $loader01_heavy_color;}
}

.loader-grey {
    height:500px;
    $loader02_light_color:  rgba(0,0,0, 0.05);
    $loader02_heavy_color:  rgba(0,0,0, 0.10);
    .loader-circle {box-shadow: inset 0 0 0 1px $loader02_light_color;}
    .loader-line-mask {mask-image: -webkit-linear-gradient(top, #000000, $loader02_light_color);}
    .loader-line-mask .loader-line {box-shadow: inset 0 0 0 1px $loader02_heavy_color;}
}
