
$headroom_background: $footer-color;
$headroom_background_fade_out: 1s;
$headroom_background_opacity: 0.98;
$headroom_speed_in: 0.4s;
$headroom_speed_out: 0.8s;




$headroom_desktop_move : 93px;
$headroom_tablet_move : 93px;
$headroom_mobile_move : 102px;


.background {
    background: lighten(#002338, 2%);
}




header#header.home.headroom {
    will-change: transform;
    .background {
        transition: opacity $headroom_background_fade_out;
        opacity: $headroom_background_opacity;
    }
    &.headroom--top {
        .background {
            // opacity: 0!important;
            transition: opacity $headroom_background_fade_out;
        }
    }
    &.headroom--pinned {
        @include brk($t) {  }
        @include brk($m) {  }
        .background {
            transition: opacity $headroom_background_fade_out;
            opacity: $headroom_background_opacity;
        }
    }

    // ETAT SMALL --------------
    &.headroom--pinned:not(.headroom--top),
    &.headroom--unpinned:not(.stop-animation) {
        box-shadow: 0px 3px 30px 0px transparentize(#000, 0.5);
        transform: translateY(- $headroom_desktop_move);
        @include brk($t) { transform: translateY(- $headroom_tablet_move) }
        @include brk($m) { transform: translateY(- $headroom_mobile_move) }
        .background {
            opacity: $headroom_background_opacity;
        }
        @include brk(min-width $t) {
            .logo-image { transform: translate3d(-14px,24px,0) scale(0.65);}
            .icon-container.facebook { transform: translate3d(0px,25px,0) scale(1);}
            .wpml-container {transform: translate3d(0,26px,0);}
            #nav_desktop>ul>li {
                >a>span { transform: translate3d(0,25px,0); }
                &:not(.btn)>a::before {
                    transform: translate3d(0,31px,0);
                }
            }
        }
        @include brk($td) {
            .logo-image { transform: translate3d(-7px,45px,0) scale(0.75);}
        }
        @include brk($m) {
            .logo-image { transform: translate3d(-20px,75px,0) scale(1);}
        }
    }


    &.stop-animation {
        // transition-duration: 0s !important;
    }
}




$headroom_page_desktop_move : 40px;
$headroom_page_tablet_move : 0px;
$headroom_page_mobile_move : 0px;






header#header.headroom:not(.home) {
    will-change: transform;
    .background {
        transition: opacity $headroom_background_fade_out;
        opacity: $headroom_background_opacity;
    }
    &.headroom--top {
        .background {
            // opacity: 0!important;
            transition: opacity $headroom_background_fade_out;
        }
    }
    &.headroom--pinned {
        @include brk($t) {  }
        @include brk($m) {  }
        .background {
            transition: opacity $headroom_background_fade_out;
            opacity: $headroom_background_opacity;
        }
    }

    // ETAT SMALL --------------
    &.headroom--pinned:not(.headroom--top),
    &.headroom--unpinned:not(.stop-animation) {
        box-shadow: 0px 3px 30px 0px transparentize(#000, 0.5);
        transform: translateY(- $headroom_page_desktop_move);
        @include brk($t) { transform: translateY(- $headroom_page_tablet_move) }
        @include brk($m) { transform: translateY(- $headroom_page_mobile_move) }
        .background {
            opacity: $headroom_background_opacity;
        }
        @include brk(min-width $t) {
            .logo-image { transform: translate3d(-10px,20px,0) scale(0.6);}
            .icon-container.facebook { transform: translate3d(0px,20px,0) scale(1);}
            .wpml-container {transform: translate3d(0,20px,0);}
            #nav_desktop>ul>li {
                >a>span { transform: translate3d(0,20px,0); }



                // SPANS MENU
                &:not(.btn)>a {
                    >span { transform: translate3d(0,19px,0); }
                    // souligné
                    &::before {
                        transform: translate3d(0,0px,0);
                        // height:3px;
                    }
                }
            }


        }
        @include brk($td) {
            .logo-image { transform: translate3d(0px,0px,0) scale(1);}
        }
        @include brk($m) {
            .logo-image { transform: translate3d(0px,0px,0) scale(1);}
        }
    }


    &.stop-animation {
        // transition-duration: 0s !important;
    }
}


