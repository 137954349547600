




.single.events {
	.top-container {

		.title-container {
			height:auto!important;
			line-height: 1!important;
		}
		p {
            font-size:13px;
            font-weight: $bold;
            font-family: $family2;
            letter-spacing: 1px;
            text-transform: uppercase;
            display: inline-block;
            color:transparentize(#000, 0.5);
            animation: fade 1s;
        }
        .date {
            margin-right:10px; 
        }
        .place, .country {
        }
        .place {
            &:after {
                content:"|";
                margin-left: 5px;
                margin-right:1px; 
                color:transparentize($touche1, 0.5);
            }
            
        }
        .title {
        	padding-top: 20px;
			border-bottom: 1px solid $touche1;
        }
		
	}
}







