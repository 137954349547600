$header-background-color: transparentize(#ffffff, 0.04);
$header-elements-home-margin-top : 50px;



#header_behind {
    height:$header-height;
    transition: height 1s;
    // display: none;
    // background:#fff;
    background:lighten(#002338, 0%);
    background: lighten(#002338, 2%);
    &.home {
        height:$header-height-home;
    }
    @include brk ($t) {height:$header-height-tablet;}
    @include brk ($m) {height:$header-height-mobile;}
}

$header_shadow_height: 40px;
#header_shadow {
    width:100%;
    opacity: 0.2;
    height:$header_shadow_height;
    background: url('../assets/images/shadow-header.png');
    background-repeat: repeat-x;
    background-size: 100% $header_shadow_height;
    position: absolute;
    left:0;
    z-index: -1;
    bottom: - $header_shadow_height;
}

header#header {
    height:$header-height;
    position:fixed;
    top:0px;
    left:0px; 
    right:0;
    z-index: 99;
    transition: transform 0.6s, box-shadow 0.6s;
    &.home {
        height:$header-height-home;
    }
    @include brk ($t) {height:$header-height-tablet;}
    @include brk ($m) {
        height:$header-height-mobile;
    }
    .banner-content {
        height: 100%;
        transition: 0.5s;
        @include brk($header-getLarge) {
            padding:0 60px;
        }
        @include brk($t){padding-left: $padding-tablet; padding-right: $padding-tablet;}
        @include brk($m){padding-left: $padding-mobile; padding-right: $padding-mobile;}
    }
}









#header .logo-container {
    height:$header-height;
    line-height: $header-height;
    transition: height 1s, line-height 1s;
    width:80px;
    float: left;
    z-index: 1;
    position:relative;
    display: block;
    &.home {
        margin-top: $header-elements-home-margin-top + 4px;
        @include brk($m) {
            margin-top: 30px;
            transform: translate3d(20px,0,0);
        }
    }
    @include brk ($t) {
        height:$header-height-tablet;
        line-height: $header-height-tablet;
        width: 45px;
    }
    @include brk ($m) {
        height:$header-height-mobile;
        line-height: $header-height-mobile;
        width: 40px;
        margin-top: 0px;
        margin-left: 0;
    }
    .logo-image {
        display: inline-block;
        height:100%;
        z-index: 10;
        width:100%;
        transition: transform 0.6s;
        path {fill:#fff}
        @include brk($m) {
            // transform: translate3d(7px,1px,0) scale(0.75);
        }
    }
    .bottom-line {
        color:transparentize(#fff, 0.3);
        // color:$touche1;
        font-size: 10px;
        letter-spacing: 0.4px;
        font-weight: $bold;
        font-family: $family2;
        text-transform: uppercase;
        display: block;
        position: absolute;
        bottom:14px;
        left:0;
        right:0;
        text-align: center;
    }
    .bird {
        opacity: 0.2;
        transform-origin: 50% 50%;
        transform: scale(1.2) translate(0,-10px);
        // display: none;
    }
    .letters {
        // display: none;
        opacity: 1;
    }
}






header .icon-container {
    display: inline-block;
    float: right;
    height: 40px;
    width: 40px;
    position: relative;
    margin-left: 0px;
    margin-right: 16px;
    border: 1px solid transparentize(#fff, 0.8);
    border-radius:50%;
    margin-top: 35px;
    transition: transform 0.6s;
    &.home {
        margin-top: 85px;
    }
    @include brk($td) {
        display: none;
    }
    .icon {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    * {
        fill:#fff;
    }
    &:hover {
        background: darken(#002942, 10%);
        border: 1px solid transparentize(darken(#002942, 10%), 0);
    };
}














#nav_desktop {
    display:inline-block;
    height:$header-height;
    z-index: 100;
    float: right;
    margin-right:15px;
    transition: margin-right 1s;
    // right:84px;
    // position: absolute;
    // top:0px;
    &.home {
        margin-top: $header-elements-home-margin-top;
    }
    @include brk($t) {display:none;}
    >ul { height:100%; }
    >ul>li {
        display:inline-block;
        height: 100%;
        float: left;
        position: relative;
        height:100%;
    }
    >ul>li>a {
        font-size: 14px;
        font-weight: $bold;
        font-family: $family2;
        text-transform: uppercase;
        height: 100%;
        transition: height 1s, line-height 1s;
        display: inline-block;
        position: relative;
        min-width: 66px;
        padding: 43px 18px 5px;
        transition: color 0.3s;
        color: transparentize(#fff,0.2);
        letter-spacing: 1px;
        user-select:none;
        span {
            transition: transform 0.6s;
            display:inline-block; 
            position: relative;
            display: inline-block;
            width: 100%;
        }
    }
    >ul>li:hover>a, 
    >ul>li.current-menu-item>a,
    >ul>li.current-menu-ancestor >a {
        color: transparentize(#fff,0);
    }


    // HOME ICON ----------------------- 

    // >ul>li:first-child p { display:none;}
    // >ul>li:first-child .icon.home {
    //     position: absolute;
    //     top:30px;
    //     left:22px;
    //     width:22px;
    //     height:22px;
    //     path {fill:$touche_de_couleur}
    // }


    // SOULIGNÉ ----------------------- 

    >ul>li:not(.btn)>a:before {
        content:"";
        width:0px;
        opacity: 0;
        transition: width 0.2s, opacity 0.5s, background 0.3s, transform 0.6s;
        height:3px;
        position: absolute;
        display: inline-block;
        background:$touche1;
        // background:#fff;
        left:18px;
        bottom:0px;
    }
    >ul>li:not(.btn):hover >a:before,
    >ul>li:not(.btn).current-menu-ancestor >a:before,
    >ul>li:not(.btn).current-menu-item >a:before {
        opacity: 1;
        width:calc(100% - 37px);
    }
    &.home {
        >ul>li:not(.btn)>a:before {
            bottom:21px;
            height:2px;
        }
    }



    // SEPARATION VERTICALE -----------------

    >ul>li:before {
        content:"";
        width:1px;
        height:20px;
        position: absolute;
        display: inline-block;
        background:rgba(0,0,0,0.05);
        top:33px;
        left:-4px;
    }
    >ul>li:first-child:before {display:none;}



    // FLECHE PARENT -----------------

    >ul>li.has-children {
        a {padding-right:15px;}
        .icon.arrow {
            position: absolute;
            top:calc(50% - 9px);
            right:18px;
            width:9px;
            height:9px;
            .st0 {
                transition: fill 0.6s;
                fill: $touche1;
            }
        }
    }


    // BUTTONS -----------------------------------

    >ul>li.btn {
        height:$header-height;
        line-height:$header-height;
        transition: height 1s, line-height 1s;
        margin-right:5px;
        margin-left:20px;
        a {
            @extend .bloc;
            height:auto;
            margin-bottom: 7px;
            transition: none;
            border-radius:10px;
            border:1px solid $touche1;
            padding: 12px 25px;
            color:$touche1;
        }
        &.active a, a:hover{

            background: $touche1;
            color:$white;

        }
    }
}











// DESKTOP LEVEL 3
// ------------------------------------------------------

// #nav_desktop >ul>li>ul>li {
//     &.menu-item-has-children.current-menu-item ul{display:block}
//     >ul {
//         display: block;
//         position:absolute;
//         top:100px;
//         left:0px;
//         height:100px;
//         display:none;
//         background:$level3; width:40000px;
//         >li {
//             display:inline-block;
//             float:left;
//             height:100%;
//             &:first-child a {padding-left:0}
//             a {
//                 padding-top:42px;
//                 font-size:13px;
//                 font-weight: 700;
//                 color:#333333;
//                 &:hover {color:$dark-grey;}
//             }
//         }
//     }
// }








#header .social-icons {
    // background:green;
    height:100%;
    float:right;
    animation: fade 2.5s;
    margin-right: -24px;
    @include brk ($tablet) {display:none;}
    .icon-container {    
        float: left;
        height:$header-height;
        line-height:$header-height;
        transition: height 1s, line-height 1s;
        width:80px;
        position: relative;
        text-align: center;
        .icon {
            width: 37px;
            height: 37px;
            @extend .bloc;
            margin-bottom: 7px;
            svg {
                transform: scale(0.8);
                transition: transform 0.5s;
            }
            .st0 {
                fill:#c9c9c9;
                transition: fill 0.3s;
            }
        }
        &:hover {
            .icon svg { transform: scale(1);}
            .icon .st0 {fill: $touche2}
            // .icon .st0 {fill: rgba(255,255,255,1)}
        }
        &.search {
            cursor: pointer;
            .icon {
                width: 30px;
                height: 30px;
                transition: transform 0.5s, background 0.6s;
                background:$touche1;
                border-radius:50%;
                svg {transform:scale(0.55)}
                path {fill:#fff;}
            }
        }
        &.search:hover {
            .icon.search {
                transform: scale(1.2);
                svg { transform: scale(0.5);}
            }
        }
        &.facebook:hover {
            // .icon .st0 {fill: #3B5998}
        }
    }
}















// WPML -----------------------------------------------------

#header .wpml-container {
    height: $header-height;
    position: relative; 
    display: inline-block;
    float:right;
    margin-right: 0px;
    transition: margin-right 0.5s, transform 0.6s;
    @include brk($header-getLarge) {
        margin-right: 0px;
    }
    @include brk($t) {
        display: none;
    }
    &.home {
        margin-top: $header-elements-home-margin-top;
    }
}

#header .wpml-ls-legacy-dropdown {
    >ul {
        height:$header-height;
        >li { outline: 0 none !important; }
    }
    a {
        font-size: 12px;
        font-weight: $light;
        font-family: $family1;
        color: transparentize(#fff,0.1);
        text-shadow: 4px 2px 6px  transparentize(#000, 0.6);
        img {
            width:18px;
            height:auto;
            box-shadow: 4px 2px 6px 2px transparentize(#000, 0.88);
        }
    }
    >ul>li>a {
        height:$header-height;
        padding:  43px 16px 10px 12px;
        position: relative;
        img {
            margin-top: 5px;
            margin-right: 8px;
        }
        &::after {
            content:"";
            width:8px;
            height:8px;
            position:absolute;
            top:53px;
            right:8px;
            background-size:contain!important;
            background: url('../assets/images/wpml_down.svg');
            display: none;
        }
        span {
            position: relative;
            display: none;
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        position:absolute;
        // height:100%;
        top:72px;
        left:-38px;
        z-index: 100;
        padding: 0 50px 80px 30px;
    }
    >ul>li>ul>li {
        width: 176px;
        background: lighten(#041a26, 10%);
        // background:#eeeeee;
        display: block;
        margin-bottom: 0px;
        // &:hover {background:$white;}
        &:first-child>a { padding-top: 14px!important; }
        &:last-child>a { padding-bottom: 9px!important; }
    }
    >ul>li>ul>li>a {
        // box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        display: inline-block;
        // height:100%;
        text-align: center;
        padding:1px 10px 1px 20px;
        img {
            margin-top: 3px;
            margin-right: 7px;
            float: left;
            filter: brightness(90%);
        }
        span {
            display: inline-block;
            float: left;
            color:transparentize(#fff, 0.3);
        }
        &:hover {
            img {
                filter: brightness(100%);
            }
            span {
                color:transparentize(#fff, 0);
            }
        }
    }
}