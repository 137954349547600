



$page_wrapper-bannerTop-desktop: 500px;
$page_wrapper-bannerTop-laptop: 500px;
$page_wrapper-bannerTop-tablet: 450px;
$page_wrapper-bannerTop-tablet_p: 400px;
$page_wrapper-bannerTop-mobile: 348px;



// .page.gdpr .banner.top {
//     background:$footer-color;
//     transition: height 1s;
//     height:$page_wrapper-bannerTop-desktop;
//     @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
//     @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
//     @include brk($tp) { height:$page_wrapper-bannerTop-tablet_p; }
//     @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
// }
// .page.gdpr .banner.top .bloc-container {
//     transition: line-height 1s;
//     line-height:$page_wrapper-bannerTop-desktop;
//     @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
//     @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
//     @include brk($tp) { line-height:$page_wrapper-bannerTop-tablet_p; }
//     @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
// }



.page.gdpr .banner.top {
    // position: relative;
    // .image {
    //     background-position: 50% 50%;
    //     filter: brightness(80%);
    //     // filter: grayscale(100%);
    //     // filter: saturate(0%);
    //     // &::after {
    //     //     @extend .overlay;
    //     //     content:"";
    //     //     background: transparentize(#212121, 0.3);
    //     // }
    //     @include brk($m) {
    //         background-position: 50% 50%;
    //     }
    // }
    // .bloc {
    //     padding: 60px 20% 0 0;
    //     // text-align: center;
    //     @include brk($t) {
    //         padding: 60px 20% 0 0;
    //     }
    //     @include brk($m) {
    //         padding: 60px 20% 0 0;
    //     }
    // }
    // .titre {
    //     font-size:50px;
    //     font-family: $family2;
    //     color:#fff;
    //     animation: fade 2s;
    //     line-height: 1.1;
    //     letter-spacing: 4px;
    //     position: relative;
    //     text-transform: uppercase;
    //     z-index: 4;
    //     user-select:none;
    //     margin-bottom:10px;
    //     @include brk($t) { font-size: 42px; letter-spacing: 4px;}
    //     @include brk($m) { font-size: 28px; letter-spacing: 3px;}
    // }
    // .sous-titre {
    //     font-size:11px;
    //     color:#fff;
    //     letter-spacing:2px;
    //     animation-delay: 0.2s;
    //     text-transform:uppercase;
    // }
    // .scroll-btn {
    //     left:auto!important;
    //     right:0!important;
    //     @include brk($tp){display:none}
    // }
    
}









.page.gdpr .banner.medium {
	.title {
		text-transform: uppercase;
		font-family:$family2;
		font-size:34px;
		animation: fade 1.5s;
	}
	.col-right {
		* {
			animation-delay: 0.3s;
			animation: fade 1.5s;
		}
	}
	// padding:100px 0 150px;
	// @include brk($l) {padding:90px 0 120px;}
	// @include brk($td) {padding:70px 0 100px;}
	// @include brk($tp) {padding:60px 0 80px;}
	// @include brk($m) {padding:50px 0 150px;}

	// .content {
	// 	animation: fade 2s;
	// 	h2 {
	// 	}
	// 	h4 {
	// 	}
	// }
	
}



















// BANNER COOKIE

$gdrp_banner_cookie_h: 42px;


.banner.gdpr {
	background: $footer-color;
	background: transparentize(#000, 0.10);
	position: fixed;
	bottom:-0.5px;
	left:0;
	right:0;
	z-index: 1000;
	opacity:0;
	transform: translateY(60px);
	transition: opacity 1s, transform 0.5s;
	transition-delay: 0.1s;
	&.visible {
		opacity:1;
		transition: opacity 1.5s, transform 1.9s;
		transform: translateY(0px);
		transition-delay: 0s;
		a, p, button {
			opacity:1!important;
		}
		p {
			transition-duration: 1s!important;
			transition-delay: 0.6s!important;
		}
		a {
			transition-duration: 1s!important;
			transition-delay: 0.8s!important;
		}
		button {
			transition-duration: 1s!important;
			transition-delay: 0.8s!important;
			transform: scale(1)!important;
		}
	}
	p, a, button {
		font-size:12px;
		@include brk($tp) { font-size:11px; }
		@include brk($m) {  font-size:10px; }
	}
	.content {
		text-align:right;
		margin-bottom:1px;
		.text-container {
			display:inline-block;
			height:$gdrp_banner_cookie_h;
			line-height:$gdrp_banner_cookie_h;
			text-align:right;
			@include brk($m) {
				text-align:left;
				max-width:220px;
				float:left;
			}
			.bloc {
				vertical-align: initial!important;
				@include brk($m) {
					vertical-align: middle!important;
					// padding-bottom:3px;
				}
				p, a {
					display:inline-block;
					font-family:$family1;
					line-height:1.1;
					opacity:0;
					user-select:none;
					letter-spacing:0;
					color:transparentize(#fff, 0);
				}
				p {
					transition-property: opacity;
					transition-duration: 0.2s;
					transition-delay: 0.1s;
				}
				a {
					transition: opacity 0.2s;
					margin-left:4px;
					transition-duration: 0.3s;
					transition-delay: 0.05s;
					transition: opacity 0.2s, color 0.1s;
					-webkit-text-stroke: #fff;
		    		text-decoration: underline;
		    		&:hover, &:focus {
			    		transition: color 0s!important;
			    		color:$touche1;
		    		}
				}
			}
		}
		.button-container {
			display:inline-block;
			height:$gdrp_banner_cookie_h;
			line-height:$gdrp_banner_cookie_h;
			margin-left:30px;
			@include brk($m) {
				margin-left:16px;
			}
			button {
				@extend .bloc;
				vertical-align: initial!important;
				height:35px;
				border: 0;
				cursor:pointer;
				font-weight:$bold;
				font-family:$family1;
				transition: transform, opacity;
				opacity:0;
				transform: scale(0);
				transition-duration: 0.3s;
				transition-delay: 0s;
				letter-spacing:0.5px;
				background:$touche1;
				color:#fff;
				padding: 0px 13px 0;
				&:hover, &:focus {
					background:$footer-color;
				}
				@include brk($tp) { 
					padding: 2px 11px 0px;
				}
				@include brk($m) {
				}
			}
		}
	}
}




















