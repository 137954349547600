




.page .banner.medium .flexible {
    li:first-child .flex-titre {
        margin-top: 0!important;
    }
    .flex {
        .titre {
            font-size: 32px;
            line-height: 1.05;
            font-weight: $bold;
            color:$touche1;
            @include brk ($m) { font-size: 20px; }
        }
        .chapeau p {
            font-size: 16px;
            font-weight: $light;
            color:$touche1;
            letter-spacing: 0.2px;
            line-height: 1.8;
        }
    }
}







.page .flex {
    .banner-content { padding:0; }

    &.flex-titre {
        margin-bottom: 0px;         
        margin-top: 70px;           
    }

    &.flex-wysiwyg {
        margin-bottom: 40px;
    }


    &.flex-image {
        height:337px;
        margin-bottom: 25px;
        @include brk ($t) { height:290px; }
        @include brk ($m) { height:118px; }
        &.has-lightbox .image {
            cursor:pointer;
        }
    }


    &.flex-focus {
        background:#fff;
        position: relative;
        padding: 25px 45px 0px;
        margin-bottom:40px;
        .titre {
            font-size: 17.5px;
            padding-bottom:5px;
            font-weight: $semi-bold;
        }
        &:before {
            content:"";
            display: inline-block;
            height:100%;
            width:6px;
            position: absolute;
            left:0; top:0;
            background:$touche1;
        }
    }


    &.flex-accordeon {
        .ul-accordeon {
            // margin-bottom:120px;
        }
        .ul-accordeon>li {
            position: relative;
            display: inline-block;
            display: inline-block;
            width:100%;
            animation: fade 1s;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            .bloc-titre-container {
                cursor:pointer;
                padding-right: 45px;
                display: inline-block;
                width:100%;
                position: relative;
                .bloc-titre {
                    padding: 24px 0 26px;
                }
                &:hover {
                    .icon-container {
                        background:$touche1;
                        // transform: scale(1);
                    }
                    .st0{stroke:#fff !important;}
                }
                .date {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    font-weight: $bold;
                    color:$touche1;
                    padding-bottom:0;
                    user-select:none;
                    margin-right: 10px;
                    // animation: fade 2s;
                }
                .titre {
                    cursor:pointer;
                    display: inline;
                    font-size: 14.5px;
                    // animation: fade 2s;
                    font-weight: $normal;
                    // user-select:none;
                    color:$touche1;
                }
                .chapeau p {
                    font-size: 12px;
                    font-weight: $light;
                    color:#797979;
                    letter-spacing: 0.2px;
                    line-height: 1.8;
                    padding-bottom:0;
                    cursor: pointer;
                }
                .icon-container {
                    display: inline-block;
                    width:38px;
                    height:38px;
                    background:#fff;
                    border-radius:50%;
                    position: absolute;
                    right:0px;
                    top:17px;
                    text-align: center;
                    cursor: pointer;
                    // transform: scale(1.05);
                    transition: transform 0.3s;
                    .icon {
                        width:18px;
                        height:18px;
                        margin-top: 10px;
                        transition: transform 0.2s;
                    }
                    svg {
                        transition: transform 0.3s;
                        transform: scale(0.5);
                    }
                    .st0{fill:none;stroke:#82827f;stroke-width:8;stroke-miterlimit:10;}
                    .hor {transition: opacity 0.2s;}
                }
            }
            .bloc-contenu {
                padding-top: 10px;
                padding-bottom: 20px;
                opacity: 0;
                transition: opacity 0.5s;
                overflow: hidden;
                .image-container {
                    position: relative;
                    height:337px;
                    margin-bottom: 25px;
                    @include brk ($t) { height:290px; }
                    @include brk ($m) { height:118px; }
                    &.has-lightbox {cursor:pointer}
                }
                .col {
                    position: relative;
                    height:auto;
                    .image-col {
                        transform: translate3d(0,12px,0);
                        width:100%;
                        height:auto;
                        display: block;
                        &.has-lightbox {
                            + .overlay {
                                cursor:pointer;
                                // &:hover {transition: opacity 0.5s; opacity: 1;}
                            }
                        }
                    }
                    @include brk($m) {
                        width:100%!important;
                    }
                    .overlay {
                        @extend .abs;
                        opacity: 0;
                        transition: opacity 0.3s;
                        background:rgba(0,0,0,0.1);
                        .icon {
                            width: 34px;
                            height: 34px;
                            position: absolute;
                            bottom:0;
                            right:0;
                            // transform:rotate(270deg);
                            .st0 {fill:#fff}
                        }
                    }
                }
                .col1 {width:23.2%;}
                .col2 {float:right;width:71.8%;}
            }
            &.opened {
                .bloc-contenu {transition: opacity 1.5s; opacity: 1}
                .bloc-titre .icon-container {
                    background:$touche1;
                    .st0{stroke:#fff !important;}
                }
                .bloc-titre:hover .icon-container {
                    background:#e71e4b;
                }
                .icon-container .icon {transform: rotate(180deg);}
                .icon-container .vert {opacity:0}
            }
        }
    }
}


